.optin-elem {
  &.deactivated {
    &.optin-elem-yt {
      background: #f16261 url('../../images/icons/flat_social_icons/Flat_Social_Style_01/PNG/256/03_youtube.png') center center no-repeat !important;
    }
    &.optin-elem-vimeo {
      background: #87d3e0 url('../../images/icons/flat_social_icons/Flat_Social_Style_01/PNG/256/09_vimeo.png') center center no-repeat !important;
    }
    &.optin-elem-fb {
      background: #507cbe url('../../images/icons/flat_social_icons/Flat_Social_Style_01/PNG/256/02_facebook.png') center center no-repeat !important;
    }
    &.optin-elem-twitter {
      background: #63cdf1 url('../../images/icons/flat_social_icons/Flat_Social_Style_01/PNG/256/01_twitter.png') center center no-repeat !important;
    }
    &.optin-elem-instagram {
      background: #c7c5b3 url('../../images/icons/flat_social_icons/Flat_Social_Style_01/PNG/256/10_instagram.png') center center no-repeat !important;
    }
    &.optin-elem-map-google,
    &.optin-elem-map-osm {
      background: #c7c5b3 url('../../images/map-placeholder.jpg') center center no-repeat !important;
      background-size: cover !important;
    }
  }
}

.embedded-content,
.embed-responsive,
.optin-elem {
  &.allow-overflow {
    overflow: visible !important;
  }
}

.optin-elem-fb.deactivated,
.optin-elem-twitter.deactivated,
.optin-elem-instagram.deactivated {
  padding-top: @child-image-aspect-ratio-padding;
  border-radius: @border-radius-large !important;
  position: relative;
}

#page #content-section {
  .infobox.optin {
    display: flex;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    overflow: visible;
    margin: 0 !important;
    width: ~"calc(100% + 1px)";
    min-height: ~"calc(100% + 1px)";
    border-radius: @border-radius-large;
    flex-direction: column;
    justify-content: center;
  }
  header {
    .infobox.optin {
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      min-height: 0;
      background: none;
      padding: 0 !important;
      .cell {
        padding: 1.5rem 2rem !important;
        background: @gray-lightest;
      }
    }
  }
}

#page #content-section header {
  .infobox.optin {
    border-radius: 0;
  }
}