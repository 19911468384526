@media print {
  body{font-size: 20px;}

  .container {
    width: 96%;
  }

  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
  }

  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }

  a[href]:after {
    content: none;
  }

  .logo-box img {
    max-width: 300px !important;
    height: auto;
  }

  .header-address-box {
    font-size: 0.8em;
    p {
      font-size: 0.8em;
    }
    h3 {
      font-size: 0.9em;
    }
  }

  .header-title {
    text-align: left !important;
    color: @text-color !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
    text-shadow: none !important;
    font-weight: normal !important;
  }

  h1.header-text-one {
    margin-top: 0;
  }

  .footer-social-box {
    clear: both;
    width: 100%;
    font-size: 0.9em !important;
    .text-center {
      text-align: left;
      a {
        display: block;
        padding: 0;
        font-size: 0.8em;
      }
      a[href]:after {
        content: " (" attr(href) ")";
        font-size: 0.8em;
      }
    }
  }

  .footer-top-address {
    clear: both;
    width: 100%;
    font-size: 0.8em;
    p {
      font-size: 0.8em;
    }
    h3 {
      font-size: 0.9em;
    }
  }

  .footer-copyrights-box {
    clear: both;
    width: 100%;
    font-size: 0.8em;
  }
}