.other-titles a:link,
.other-titles a:visited,
.header-text-one a:link,
.header-text-one a:visited {
  color: @link-color;
}

.other-titles a:hover,
.header-text-one a:hover,
.header-text-two a:hover {
  color: @link-color;
}

.header-text-two a:link,
.header-text-two a:visited {
  color: @link-color;
}

.teaser {
  .other-titles a:link,
  .other-titles a:visited,
  .header-text-one a:link,
  .header-text-one a:visited {
    color: @link-color !important;
  }

  .other-titles a:hover,
  .header-text-one a:hover,
  .header-text-two a:hover {
    color: @link-color !important;
  }

  .header-text-two a:link,
  .header-text-two a:visited {
    color: @link-color !important;
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  text-align: left;
  &.header-title {
    text-align: center;
  }
}

// Footer
.footer-top .white,
.footer-bottom .white,
.footer-top .white *,
.footer-bottom .white *{
  color: @footer-text-color;
}

.footer-top abbr {
  border-bottom: 1px @footer-text-color dotted;
}

// Navi
nav a {
  font-weight: normal;
}

#page h1.header-title {
  text-shadow: 2px 2px #434343;
}