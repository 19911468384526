// BS RESETS
// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.
.navbar-alignment(){
  .navbar-right {
    .dropdown-menu {
      .dropdown-menu-right();
    }
    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      .dropdown-menu-left();
    }
  }
}

// Zurücksetzen von Bootstrap
.nav-reset(){
  //Abhängig von Navi, Breakpoint, Scrolled und Back-Button muss der Innen-Abstand vom Body angepasst werden
  body {
    padding-top: 6rem;
    &.has-back-button {
      padding-top: 10rem;
      .navbar.navbar-fixed-top {
        .box-sizing('border-box');
        border-color: @navbar-default-bg !important;
      }
    }
    @media (min-width: @screen-sm-min) {
      &.scrolled {
        padding-top: 4rem;
        &.has-back-button {
          padding-top: 8rem;
        }
      }
    }
  }

  .navbar.navbar-fixed-top {
    height: 6rem;
    margin: 0;
    .transition(height 0.2s linear);
    .transition(top 0.2s linear);
    .box-sizing(border-box);
    line-height: 4.5rem;
    border-radius: 0 !important;
    .container-fluid,
    .container {
      .navbar-header {
        float: none !important;
        margin: 0;
        position: relative;
        z-index: 10;
      }
    }
    .header-search-box {
      .dropdown-menu {
        top: 6rem;
      }
    }
    .navbar-brand {
      position: absolute !important;
      top: 0;
      left: 0;
      margin: 0;
      .transition(padding 0.2s);
      .box-sizing(border-box);
      padding: 0.75rem 1rem;
      font-size: 1.5rem;
      line-height: 6rem;
      height: 6rem;
      img {
        .transition(height 0.2s linear);
        height: 4.5rem;
        width: auto;
      }
      &.original-size{
        padding: 0.75rem 1rem !important;
        line-height: 6rem !important;
        .transition(padding 0s);
        img {
          height: 4.5rem !important;
          width: auto !important;
          .transition(height 0s linear);
        }
      }
    }
    .navbar-collapse {
      border: none;
      background: none;
    }
    .navbar-nav {
      margin: 0;
      ul.dropdown-menu {
        padding: 0;
        border: none;
      }
    }

    &.scrolled {
      .opacity(100);
    }
    &.fadeAway {
      top: -6rem;
      .header-search-box {
        .dropdown-menu {
          top: 0rem !important;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      &.scrolled {
        height: 4rem;
        min-height: 4rem;
        line-height: 4rem;
        .navbar-brand {
          padding: 0.75rem 1rem 0.75rem 1rem;
        }
        .header-search-box {
          .dropdown-menu {
            top: 4rem;
          }
        }
      }
    }
  }

  .dropdown-submenu .dropdown-menu {
    position: relative !important;
  }

  .fa-external-link {
    padding-left: 0.25rem;
    top: 1px;
    position: relative;
  }

  .header-search-box {
    .dropdown-menu {
      border-radius: 0 !important;
      .box-shadow(none);
      position: fixed;
      left: 0 !important;
      top: 5rem;
      width: 100%;
      padding: 1rem 0 !important;
      background: @white;
      border-bottom: 1px @gray-lighter solid !important;

      /*
      input[type="search"],
      input[type="search"]:focus {
        background: @white !important;
        border-bottom: none !important;
      }

      input[type="search"]:-webkit-autofill,
      input[type="search"]:-webkit-autofill:hover,
      input[type="search"]:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px @white inset !important;
      }
      */

    }
  }
}

// Zurücksetzen von Bootstrap für Desktop-Menü
.nav-reset-desktop() {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
    &.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0; // Override default setting
      overflow: visible !important;
    }
    &.in {
      overflow-y: visible;
    }
    // Undo the collapse side padding for navbars with containers to ensure
    // alignment of right-aligned contents.
    .navbar-fixed-top &,
    .navbar-static-top &,
    .navbar-fixed-bottom & {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .container,
  .container-fluid {
    > .navbar-collapse {
      margin-right: 0;
      margin-left:  0;
    }
  }
  .navbar-toggle,
  .language-btn,
  .header-search-box.mobile {
    display: none;
  }
  .navbar-nav {
    > li {
      float: left;
    }
  }
}

.moveInFromRight(){
  .transition(left 0.3s linear) !important;
  max-height: none !important;
  height: 100vh !important;
  position: fixed;
  display: block !important;
  left: 100%;

  &.collapse:not(.in) {
    display: block !important;
  }

  &.navbar-collapse.collapsing {
    .transition(none) !important;
  }

  &.slideIn,
  &.slideIn.collapsing,
  &.slideOut,
  &.slideOut.collapsing{
    .transition(left 0.3s linear) !important;
  }
  &.slideOut {
    left: 100% !important;
  }


  ul.navbar-nav {
    height: 100% !important;
  }
}

// Einstelungen für mobiles Menü
.mobile-menu() {
  html.noscroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }
  .navbar.navbar-fixed-top {
    &:not(.justified-with-content-mobile) > .container {
      width: 100% !important;
      max-width: none !important;
    }

    @media (max-width: @screen-sm-max) {
      & > .container {
        width: 100% !important;
        max-width: none !important;
      }
    }

    &:not(.justified-with-content-mobile){
      .container {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .container-fluid {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    button.navbar-toggle,
    .language-btn,
    .header-search-box {
      float: right;
      border: none;
      border-radius: 0;
      background: none !important;
      display: block;
      .transition(margin 0.2s linear);
      margin: 1.5rem 1rem;
      padding: 0;
      font-size: unset;
      span, a {
        display: none;
        font-size: 1.579rem!important;
        line-height: 3rem;
        padding-right: 1rem;
        .transition(padding 0.2s linear);
        float: left;
        text-decoration: none !important;
      }
      a,
      a span {
        display: inline-block;
      }
      @media (min-width: @screen-sm-min) {
        span, a {
          display: inline-block;
        }
      }
      i {
        float: right;
        &.fa-bars {
          display: none;
        }
      }
      .fa-bars,
      .fa-times {
        line-height: 3rem;
        font-size: 3rem;
        width: 3rem;
        .transition(font-size 0.2s linear);
      }
      &.collapsed {
        .fa-bars {
          display: inline-block;
        }
        .fa-times {
          display: none;
        }
      }
    }
    .language-btn {

      a, span {
        color: @gray-light !important;
        font-style: italic;
      }
      a.dropdown-toggle {
        padding: 0 1rem 0 0;
        &:focus,
        &:hover {
          background-color: inherit !important;
          color: @gray-light !important;
        }
      }
      .caret {
        display: inline-block;
        height: 0.75rem;
        position: absolute;
        top: 1.5rem;
        right: 0.75rem;
        padding: 0 !important;
        @media (max-width: @screen-xs-max) {
          top: 1.25rem;
        }
      }

      span {
        padding-right: 0.25rem !important;
      }
      .caret {
        right: 0;
      }
      ul.dropdown-menu {
        left: 0;
        right: auto;
        min-width: 8rem;
        padding: 0;
        border: none;
        margin: 0;
        li {
          border-bottom: 1px @dropdown-border solid;
          margin: 0;
          padding: 0;
          & > a {
            display: block;
            float: none;
            padding: 0.625rem 1rem;
            line-height: 1.75rem;
            font-size: 1.184rem !important;
            margin: 0;
            white-space: normal;
            font-style: normal;
            &:focus,
            &:hover {
              background-color: @brand-primary !important;
              color: @brand-primary-contrast !important;
            }
          }
          &:first-child {
            a {
              padding: 0.5rem 1rem;
              line-height: 2rem;
              font-size: 1.579rem !important;
              color: @gray-light !important;
              font-style: italic;
              cursor: default;
              &:focus,
              &:hover {
                background-color: inherit !important;
                color: @gray-light !important;
              }
            }
          }
          &:last-child {
            border-bottom: none;
            a {
              .border-bottom-radius(4px);
            }
          }
        }
      }
    }

    // Search box
    .header-search-box {
      margin: 1.5rem 1rem;
      height: 3rem;
      padding-left: 1.5rem;
      float: right;
      width: 3rem;
      .box-sizing(content-box);
      .submit-btn {
        display: none;
      }
      .form-group,
      .full-form-control {
        margin-bottom: 0 !important;
      }
      .dropdown-menu {
        left: auto;
        right: 0;
        padding: 0;
        margin: 0;
        .border-top-radius(0);
        .border-bottom-radius(4px);
        border: none;
        min-width: 20rem;
      }
      button {
        top: 0rem;
        height: 3rem;
        width: 2.5rem;
        background: none;
        position: absolute;
        right: 0;
        font-size: 1.777rem;
        border: none;
        padding: 0;
        .caret,
        .search-icon {
          color: @gray-light;
        }
        .search-icon {
          line-height: 3rem;
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 0;
        }
        .caret {
          display: inline-block;
          height: 0.75rem;
          position: absolute;
          top: 1.5rem;
          right: 0;
          padding: 0 !important;
          @media (max-width: @screen-xs-max) {
            top: 1.25rem;
          }
        }
      }
    }

    #navbar-collapse {
      top: 6rem !important;
      background-color: @navbar-default-bg;
      padding: 0;
      margin: 0;
      &.navbar-collapse.in,
      &.navbar-collapse.collapsing {
        position: fixed;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
      }
      &.navbar-collapse.in {
        height: 100% !important;
        max-height: none;
        overflow: hidden !important;
      }
      &.navbar-collapse.collapse.in{
        max-height: none !important;
        height: 100vh !important;
        ul.navbar-nav {
          height: 100% !important;
        }
      }
      &.navbar-collapse.collapsing {
        -webkit-transition: all 0.3s linear !important;
        -o-transition: all 0.3s linear !important;
        transition: all 0.3s linear !important;
      }
    }
    #navbar-collapse.collapse:not(.in) {
      display: none !important;
    }
    .navbar-nav {
      position: relative;
      clear: both;
      height: 100vh !important;
      overflow-y: auto;
      .box-sizing(border-box);
      max-width: 100%;
      margin: 0 auto;
      padding: 2rem 1rem 6rem 1rem;
      @media (min-width: @screen-lg-min) {
        padding: 3rem 1rem 2rem 1rem;
      }
      &.navbar-right {
        float: none !important;
      }
      & > li {
        border: none !important;
        width: 100%;
        float: left;
        .box-sizing(border-box);
        &:nth-child(1n+1){
          clear: left;
        }
        &:last-child {
          padding-bottom: 6rem !important;
          ul.dropdown-menu {
            margin-bottom: 0 !important;
          }
        }
        @media (min-width: @screen-xs-min) {
          width: 48%;
          margin-bottom: 3rem;
          &:nth-child(1n+1){
            clear: none;
            margin-left: 4%;
          }
          &:nth-child(2n+1){
            clear: left;
            margin-left: 0;
          }
        }
        @media (min-width: @screen-sm-min) {
          width: 31%;
          &:nth-child(1n+1){
            clear: none;
            margin-left: 3.5%;
          }
          &:nth-child(2n+1){
            clear: none;
            margin-left: 3.5%;
          }
          &:nth-child(3n+1){
            clear: left;
            margin-left: 0;
          }
        }
        @media (min-width: @screen-md-min) {
          width: 22.75%;
          &:nth-child(1n+1){
            clear: none;
            margin-left: 3%;
          }
          &:nth-child(2n+1){
            clear: none;
            margin-left: 3%;
          }
          &:nth-child(3n+1){
            clear: none;
            margin-left: 3%;
          }
          &:nth-child(4n+1){
            clear: left;
            margin-left: 0;
          }
        }
        @media (min-width: @screen-lg-min) {
          width: 18%;
          &:nth-child(1n+1){
            clear: none;
            margin-left: 2.5%;
          }
          &:nth-child(2n+1){
            clear: none;
            margin-left: 2.5%;
          }
          &:nth-child(3n+1){
            clear: none;
            margin-left: 2.5%;
          }
          &:nth-child(4n+1){
            clear: none;
            margin-left: 2.5%;
          }
          &:nth-child(5n+1){
            clear: left;
            margin-left: 0;
          }
        }
        &:not(.dropdown){
          margin-bottom: 3rem;
        }
        &.last-li {
          margin-bottom: 10rem !important;
        }
        &.dropdown > a {
          display: none !important;
        }
        a,
        span {
          font-size: 1.333rem !important;
          line-height: 1.75rem !important;
          padding-top: 1.25rem !important;
          padding-bottom: 1.25rem !important;
          border-bottom: 1px lighten(@gray-base, 85%) solid;
          padding-left: 1rem !important;
          padding-right: 1rem !important;
          font-family: @fontNaviMobile;
          background: none !important;
          margin-top: 0 !important;
          display: block;
          position: relative;
          white-space: normal;
          color: @text-color !important;
          @media (min-width: @screen-sm-min) {
            font-size: 1.184rem !important;
            line-height: 1.5rem !important;
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
          }
          @media (min-width: @screen-lg-min) {
            font-size: 1rem !important;
            line-height: 1.5rem !important;
          }
        }
        a {
          &:hover {
            background-color: @brand-primary !important;
            color: @brand-primary-contrast !important;
            border-bottom: 1px @brand-primary solid;
          }
        }
        & > a,
        span {
          font-weight: normal;
          text-transform: uppercase;
          border: none;
          background-color: @brand-primary !important;
          color: @brand-primary-contrast !important;
          .glyphicon {
            font-size: 1rem !important;
            margin-left: 0.5rem;
            display: inline-block;
            float: right;
            line-height: 1.5rem !important;
            padding: 0 !important;
            font-family: 'Glyphicons Halflings' !important;
            color: @brand-primary-contrast !important;
            background: none !important;
          }
          &:hover,
          &:focus {
            border-bottom: none;
            background-color: darken(@brand-primary, 10%) !important;
          }
        }
        &.user-li {
          span {
            &:hover,
            &:focus {
              background-color: @brand-primary !important;
            }
          }
        }
        &.active > a {
          color: @brand-primary-contrast !important;
          background-color: lighten(@gray-base, 85%) !important;
        }
        ul.dropdown-menu {
          margin-bottom: 3rem !important;
          display: block !important;
          position: static !important;
          float: none !important;
          width: auto !important;
          margin-top: 0 !important;
          background: none !important;
          border: none !important;
          border-radius: 0;
          .box-shadow(none);
          @media (min-width: @screen-xs-min) {
            margin-bottom: 0 !important;
          }
          li {
            border: none !important;
            &.more-link {
              font-style: italic;
            }
            &:first-child {
              a {
                font-weight: normal;
                text-transform: uppercase;
                border: none;
                background-color: @brand-primary !important;
                color: @brand-primary-contrast !important;
                &:hover,
                &:focus{
                  border-bottom: none;
                  background-color: darken(@brand-primary, 10%) !important;
                }
                .glyphicon {
                  font-size: 1rem !important;
                  margin-left: 0.5rem;
                  display: inline-block;
                  float: right;
                  line-height: 1.5rem !important;
                  padding: 0 !important;
                  font-family: 'Glyphicons Halflings' !important;
                  color: @brand-primary-contrast !important;
                  background: none !important;
                }
              }
            }
            &.active > a {
              color: @text-color !important;
              background-color: @gray-lighter !important;
              &:hover {
                color: @brand-primary-contrast !important;
                background-color: @brand-primary !important;
                border-bottom: 1px @brand-primary solid;
              }
            }
          }
        }
        &.active ul.dropdown-menu li:first-child a {
          color: @brand-primary-contrast !important;
          background-color: lighten(@gray-base, 85%) !important;
        }

        li.dropdown-submenu {
          a.toggle-menu {
            .glyphicon {
              font-size: 1rem !important;
              margin-left: 0.5rem;
              display: inline-block;
              float: right;
              line-height: 1.5rem !important;
              padding: 0 !important;
              font-family: 'Glyphicons Halflings' !important;
              color: @text-color !important;
              background: none !important;
              &.glyphicon-menu-up {
                display: none;
              }
            }
            &:hover {
              .glyphicon {
                color: @brand-primary-contrast !important;
              }
            }
          }

          &.active {
            a:hover {
              .glyphicon {
                color: @brand-primary-contrast !important;
              }
            }
          }

          ul.dropdown-menu {
            display: none !important;
            margin-bottom: 0 !important;
          }

          &.open,
          &.active {
            a.toggle-menu {
              color: @brand-primary-contrast !important;
              background-color: lighten(@gray-base, 85%) !important;
              &:hover {
                color: @brand-primary-contrast !important;
                background-color: @brand-primary !important;
              }
              .glyphicon {
                color: @brand-primary-contrast !important;
              }
            }
          }
          &.open {
            a.toggle-menu {
              color: lighten(@gray-base, 85%) !important;
              background-color: lighten(@gray-base, 85%) !important;
              border-bottom: 1px @brand-primary-contrast solid !important;
              &:hover {
                color: @brand-primary !important;
                background-color: @brand-primary !important;
                border-bottom: 1px @brand-primary solid !important;
              }
              .glyphicon {
                &.glyphicon-menu-up {
                  display: inline-block;
                }
                &.glyphicon-menu-down {
                  display: none;
                }
              }
            }
            ul.dropdown-menu {
              display: block !important;
              li {
                a {
                  padding-left: 2rem !important;
                  font-size: 1.184rem !important;
                  line-height: 1.5rem !important;

                  @media (min-width: @screen-sm-min) {
                    font-size: 1rem !important;
                    line-height: 1.5rem !important;
                  }
                  @media (min-width: @screen-lg-min) {
                    font-size: 0.888rem !important;
                    line-height: 1.5rem !important;
                  }
                }
                &:first-child {
                  a {
                    padding-left: 1rem !important;
                    font-weight: normal;
                    text-transform: none;
                    border-bottom: 1px lighten(@gray-base, 85%) solid;
                    color: @text-color !important;
                    background: none !important;
                    font-size: 1.333rem !important;
                    line-height: 1.75rem !important;
                    @media (min-width: @screen-sm-min) {
                      font-size: 1.184rem !important;
                      line-height: 1.5rem !important;
                    }
                    @media (min-width: @screen-lg-min) {
                      font-size: 1rem !important;
                      line-height: 1.5rem !important;
                    }
                    &:hover {
                      color: @brand-primary-contrast !important;
                      background-color: @brand-primary !important;
                      border-bottom: 1px @brand-primary solid;
                    }
                  }
                }
              }
            }
            &.active {
              ul.dropdown-menu {
                li {
                  &:first-child {
                    a {
                      color: @brand-primary-contrast !important;
                      background: lighten(@gray-base, 85%) !important;
                      &:hover {
                        color: @brand-primary-contrast !important;
                        background-color: @brand-primary !important;
                        border-bottom: 1px @brand-primary solid;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    /* Columns per Breakpoint
      kmuapp.default.top_menu.mobile.extra_small_device.columns
      kmuapp.default.top_menu.mobile.small_device.columns
      kmuapp.default.top_menu.mobile.medium_device.columns
      kmuapp.default.top_menu.mobile.large_device.columns
    */
    @media (min-width: @screen-xs-min) and (max-width:@screen-xs-max) {
      #navbar-collapse.xs-menu-cols-2 {
        width: 100% !important;
        &.navbar-collapse.collapse.in {
          left: 0 !important;
        }
        .navbar-nav {
          & > li {
            width: 48% !important;
            margin-bottom: 3rem;
            &:nth-child(1n+1){
              clear: none;
              margin-left: 4%;
            }
            &:nth-child(2n+1){
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.xs-menu-cols-1 {
        .moveInFromRight();
        width: 66.7% !important;
        &.slideIn {
          left: 33.3% !important;
        }
        .navbar-nav {
          & > li {
            margin-bottom: 3rem;
            width: 100% !important;
            &:nth-child(1n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
    }
    @media (min-width: @screen-sm-min) and (max-width:@screen-sm-max) {
      #navbar-collapse.sm-menu-cols-3 {
        width: 100% !important;
        &.navbar-collapse.collapse.in {
          left: 0 !important;
        }
        .navbar-nav {
          & > li {
            width: 31% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 3.5%;
            }
            &:nth-child(2n+1) {
              clear: none;
              margin-left: 3.5%;
            }
            &:nth-child(3n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.sm-menu-cols-2 {
        .moveInFromRight();
        width: 66.7% !important;
        &.slideIn {
          left: 33.3% !important;
        }
        .navbar-nav {
          & > li {
            width: 48% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 4%;
            }
            &:nth-child(2n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.sm-menu-cols-1 {
        .moveInFromRight();
        width: 50% !important;
        &.slideIn {
          left: 50% !important;
        }
        .navbar-nav {
          & > li {
            width: 100% !important;
            &:nth-child(1n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
    }
    @media (min-width: @screen-md-min) and (max-width:@screen-md-max) {
      #navbar-collapse.md-menu-cols-4 {
        width: 100% !important;
        &.navbar-collapse.collapse.in {
          left: 0 !important;
        }
        .navbar-nav {
          & > li {
            width: 22.75% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 3%;
            }
            &:nth-child(2n+1) {
              clear: none;
              margin-left: 3%;
            }
            &:nth-child(3n+1) {
              clear: none;
              margin-left: 3%;
            }
            &:nth-child(4n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.md-menu-cols-3 {
        .moveInFromRight();
        width: 75% !important;
        &.slideIn {
          left: 25% !important;
        }
        .navbar-nav {
          & > li {
            width: 31% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 3.5%;
            }
            &:nth-child(2n+1) {
              clear: none;
              margin-left: 3.5%;
            }
            &:nth-child(3n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.md-menu-cols-2 {
        .moveInFromRight();
        width: 50% !important;
        &.slideIn {
          left: 50% !important;
        }
        .navbar-nav {
          & > li {
            width: 48.5% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 3%;
            }
            &:nth-child(2n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.md-menu-cols-1 {
        .moveInFromRight();
        width: 33.3% !important;
        &.slideIn {
          left: 66.7% !important;
        }
        .navbar-nav {
          & > li {
            width: 100% !important;
            &:nth-child(1n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      #navbar-collapse.lg-menu-cols-5 {
        width: 100% !important;
        &.navbar-collapse.collapse.in {
          left: 0 !important;
        }
        .navbar-nav {
          & > li {
            width: 18% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 2.5%;
            }
            &:nth-child(2n+1) {
              clear: none;
              margin-left: 2.5%;
            }
            &:nth-child(3n+1) {
              clear: none;
              margin-left: 2.5%;
            }
            &:nth-child(4n+1) {
              clear: none;
              margin-left: 2.5%;
            }
            &:nth-child(5n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.lg-menu-cols-4 {
        .moveInFromRight();
        width: 80% !important;
        &.slideIn {
          left: 20% !important;
        }
        .navbar-nav {
          & > li {
            width: 23.5% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 2%;
            }
            &:nth-child(2n+1) {
              clear: none;
              margin-left: 2%;
            }
            &:nth-child(3n+1) {
              clear: none;
              margin-left: 2%;
            }
            &:nth-child(4n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.lg-menu-cols-3 {
        .moveInFromRight();
        width: 60% !important;
        &.slideIn {
          left: 40% !important;
        }
        .navbar-nav {
          & > li {
            width: 32% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 2%;
            }
            &:nth-child(2n+1) {
              clear: none;
              margin-left: 2%;
            }
            &:nth-child(3n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.lg-menu-cols-2 {
        .moveInFromRight();
        width: 40% !important;
        &.slideIn {
          left: 60% !important;
        }
        .navbar-nav {
          & > li {
            width: 49% !important;
            &:nth-child(1n+1) {
              clear: none;
              margin-left: 2%;
            }
            &:nth-child(2n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
      #navbar-collapse.lg-menu-cols-1 {
        .moveInFromRight();
        width: 25% !important;
        &.slideIn {
          left: 75% !important;
        }
        .navbar-nav {
          & > li {
            width: 100% !important;
            &:nth-child(1n+1) {
              clear: left;
              margin-left: 0;
            }
          }
        }
      }
    }

    &.scrolled {
      #navbar-collapse {
        &.navbar-collapse.in,
        &.navbar-collapse.collapsing {
          display: block !important;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      #navbar-collapse {
        top: 4rem !important;
      }

      &.scrolled {
        height: 4rem;
        min-height: 4rem;
        line-height: 4rem;
        .navbar-brand {
          padding: 0.5rem 1rem 0.5rem 1rem;
          line-height: 4rem;
          height: 4rem;
          img {
            height: 3rem;
          }
        }
      }

      &.scrolled {
        button.navbar-toggle,
        .language-btn,
        .header-search-box {
          margin: 0.5rem 1rem;
          span {
            padding-right: 0.5rem;
            display: none;
          }
        }
        .language-btn,
        .header-search-box {
          span {
            padding-right: 0;
            display: inline-block;
          }
        }
      }
    }

    .navbar-brand {
      padding-left: 0 !important;
    }
    button.navbar-toggle {
      margin-right: 0 !important;
    }
  }
  .user-menu {
    display: none !important;
  }

  .language-li {
    display: none !important;
  }

  .header-search-box.desktop {
    display: none !important;
  }
}

// Einstellungen für Desktop-Menü
.desktop-menu() {
  .navbar.navbar-fixed-top {
    .container-fluid:not(#search-container),
    .container:not(#search-container) {
      padding: 0;
    }
    .navbar-collapse {
      position: relative;
      top: 1rem !important;
      .navbar-nav {
        margin-right: 1rem;
      }
      &.hasfeaturebox {
        .navbar-nav {
          margin-right: 0;
        }
      }
    }

    #navbar-feature-box {
      float: right;
      margin-right: 1rem;
      min-height: 1px;
    }

    // User menu
    // Search box
    .user-menu,
    .header-search-box,
    .language-li {
      margin-left: 2.75rem !important;
      height: 3rem;
      margin-top: 0.5rem;
      padding-left: 1.5rem;
      float: right;
      .submit-btn {
        display: none;
      }
      .form-group,
      .full-form-control {
        margin-bottom: 0 !important;
      }
      .dropdown-menu {
        left: auto;
        right: 0;
        padding: 0;
        margin: 0;
        .border-top-radius(0);
        .border-bottom-radius(4px);
        border: none;
        li {
          border-bottom: 1px @dropdown-border solid;
          margin: 0;
          padding: 0;
          & > a {
            padding: 0.625rem 1rem;
            line-height: 1.75rem;
            font-size: 1.184rem;
            margin: 0;
            white-space: normal;
            font-family: @fontNaviDesktop;
            &:focus,
            &:hover {
              background-color: @brand-primary !important;
              color: @brand-primary-contrast !important;
            }
          }
          &:last-child {
            border-bottom: none;
            a {
              .border-bottom-radius(4px);
            }
          }
        }
      }
      button {
        top: 0rem;
        height: 3rem;
        width: 2.5rem;
        background: none;
        position: absolute;
        right: 0;
        font-size: 1.777rem;
        border: none;
        padding: 0;
        .avatar,
        .caret,
        .search-icon {
          color: @gray-light;
        }
        .icon-user.avatar,
        .search-icon {
          line-height: 3rem;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
        }
        .search-icon {
          top: 3px;
        }
        .caret {
          display: inline-block;
          height: 0.75rem;
          position: absolute;
          top: 1.5rem;
          right: 0;
        }
      }
      .username {
        line-height: 3rem;
        padding: 0 1rem;
        font-family: @fontNaviDesktop;
        font-style: italic;
        padding: 0.625rem 1rem !important;
        line-height: 1.75rem;
        font-size: 1.184rem;
        margin: 0 !important;
        white-space: normal;
        font-family: @fontNaviDesktop;
      }
    }
    .header-search-box {
      .dropdown-menu {
        min-width: 15rem;
      }
      button {
        width: 2.25rem;
      }
    }

    .language-li {
      border-right: none !important;
      margin-left: 0.25rem !important;
      a, span {
        padding: 0;
        margin-top: 0;
        line-height: 3rem;
        font-size: 1.579rem;
        font-family: @fontNaviDesktop;
        color: @gray-light !important;
        font-style: italic;
        text-decoration: none;
      }
      a.dropdown-toggle {
        padding: 0;
        display: block;
        &:focus,
        &:hover {
          background-color: inherit !important;
          color: @gray-light !important;
        }
        .caret {
          margin-left: 0 !important;
        }
      }
      ul.dropdown-menu {
        min-width: 8rem !important;
        li {
          a {
            font-style: normal;
          }
          &:first-child {
            a {
              padding: 0.625rem 1rem;
              line-height: 1.75rem;
              font-size: 1.184rem;
              font-style: italic;
              cursor: default;
              &:focus,
              &:hover {
                background-color: inherit !important;
                color: @gray-light !important;
              }
            }
          }
        }
      }
    }

    .navbar-nav {
      .glyphicon-menu-right {
        font-size: 1rem;
        margin-left: .5rem;
        display: inline-block;
        float: right;
        line-height: inherit !important;
      }
      margin: 0;
      padding: 0;
      .pane-dropdown {
        display: none;
        overflow: auto;
        position: absolute;
        top: 100%;
        float: left;
        border-radius: 0 0 4px 4px;
        .box-shadow(0 6px 12px rgba(0,0,0,.175));
        background: @white;
        .jspHorizontalBar {
          //display: none;
          height: 0;
        }
        .jspVerticalBar {
          width: 5px;
          background: none;
          z-index: 10000;
          .jspTrack {
            background: @gray-lighter;
            .jspDrag {
              background: @gray;
            }
          }
        }
      }
      &.navbar-right {
        float: right !important;
      }
      & > li {
        border-right: 1px @navbar-default-bg solid;
        border-bottom: none;
        & > a {
          padding: 0 1.5rem;
          margin-top: 0.5rem;
          line-height: 3rem;
          font-size: 1.579rem;
          font-family: @fontNaviDesktop;
          &:focus,
          &:hover {
            background-color: @brand-primary !important;
            color: @brand-primary-contrast !important;
          }
          .glyphicon {
            font-size: 1rem;
            margin-left: 0.5rem;
            display: inline-block;
            &.glyphicon-menu-up,
            &.glyphicon-menu-right {
              display: none;
            }
          }
          .fa-external-link {
            display: none;
          }
        }
        &.open > a {
          color: @brand-primary !important;
          .glyphicon {
            color: @white !important;
            &.glyphicon-menu-up {
              display: inline-block;
            }
            &.glyphicon-menu-down {
              display: none;
            }
          }
        }
        ul.dropdown-menu {
          left: 0;
          right: auto;
          min-width: 15rem;
          margin-top: 0;
          li {
            border-bottom: 1px @dropdown-border solid;
            margin: 0;
            padding: 0;
            &.more-link {
              font-style: italic;
            }
            & > a {
              padding: 0.625rem 1rem;
              line-height: 1.75rem;
              font-size: 1.184rem;
              margin: 0;
              white-space: normal;
              i {
                margin-right: 5px;
              }
              &:focus {
                background-color: @white !important;
                color: @text-color !important;
              }
              &:hover {
                background-color: @brand-primary !important;
                color: @brand-primary-contrast !important;
              }
            }
            &.active > a,
            &.active > a:focus,
            &.active > a:hover {
              background-color: @brand-primary !important;
              color: @brand-primary-contrast !important;
            }
            &.active.open > a.toggle-menu,
            &.active.open > a.toggle-menu:focus,
            &.active.open > a.toggle-menu:hover {
              color: @brand-primary !important;
              border-radius: 0 !important;
            }
            &:first-child {
              a {
                padding: 0.5rem 1rem;
                line-height: 2rem;
                font-size: 1.579rem;
                color: @brand-primary;

                position: relative;
                padding-right: 2.5rem;
                white-space: nowrap;
                .glyphicon-menu-right {
                  position: absolute;
                  top: 10px;
                }
              }
            }
          }
          & > li:last-child {
            border-bottom: none;
            & > a {
              .border-bottom-radius(4px);
            }
          }
        }

        &.open {
          .dropdown-menu.menu_level_1,
          .pane-dropdown {
            display: block;
          }
        }

        .dropdown-menu.menu_level_1 {
          position: relative;
        }

        li.dropdown-submenu {
          ul {
            width: 100% !important;
            margin: 0;
            border-radius: 0;
            .box-shadow(none);
          }
          border-bottom: 1px @dropdown-border solid;
          margin: 0;
          padding: 0;
          & > a {
            padding: 0.5rem 1rem;
            line-height: 1.5rem;
            font-size: 1rem;
            margin: 0;
            white-space: normal;
            background-color: @white !important;
            color: @text-color !important;
            i {
              margin-right: 5px;
            }
            &:focus {
              background-color: @white !important;
              color: @text-color !important;
            }
            &:hover {
              background-color: @brand-primary !important;
              color: @brand-primary-contrast !important;
            }

            .glyphicon {
              font-size: 1rem;
              margin-left: 0.5rem;
              display: inline-block;
              float: right;
              line-height: 1.75rem;
              &.glyphicon-menu-up {
                display: none;
              }
            }
          }
          li a {
            padding: 0.5rem 1rem 0.5rem 2rem;
            line-height: 1.5rem;
            font-size: 1rem;
          }
          li:first-child {
            border-top: 1px @dropdown-border solid;
            a {
              padding: 0.625rem 1rem;
              line-height: 1.75rem;
              font-size: 1.184rem;
              color: @brand-primary;
            }
          }
          li:last-child {
            border-bottom: 1px @dropdown-border solid;
            a {
              .border-bottom-radius(0);
            }
          }
          &.open > a {
            color: @brand-primary !important;
            .glyphicon {
              color: @text-color !important;
              &.glyphicon-menu-up {
                display: inline-block;
              }
              &.glyphicon-menu-down {
                display: none;
              }
            }
            &:hover {
              .glyphicon {
                color: @brand-primary-contrast !important;
              }
            }
          }
          &.active > a,
          &.active > a:focus,
          &.active > a:hover,
          &.active .menu_level_2 li:first-child > a,
          &.active .menu_level_2 li:first-child > a:focus,
          &.active .menu_level_2 li:first-child > a:hover,
          li.active > a,
          li.active > a:focus,
          li.active > a:hover {
            background-color: @brand-primary !important;
            color: @brand-primary-contrast !important;
          }
          &.open > a,
          &.open > a:focus,
          &.open > a:hover {
            background-color: @brand-primary !important;
            color: @brand-primary !important;
          }

          &.active.open > a,
          &.active.open > a:focus,
          &.open > a,
          &.open > a:focus{
            .glyphicon {
              color: @brand-primary-contrast !important;
            }
          }
        }

        &:last-child {
          border-right: none;
          ul.dropdown-menu {
            left: auto;
            right: 0;
          }
        }
      }
    }
    &.scrolled {
      .navbar-brand {
        padding: 0.5rem 1rem;
        line-height: 4rem;
        height: 4rem;
        img {
          height: 3rem;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      &.scrolled {
        .navbar-collapse {
          top: -1rem !important;
        }
        .user-menu,
        .header-search-box,
        .language-li {
          margin-top: 1.5rem;
        }
        .navbar-nav > li > a {
          margin-top: 1.5rem;
          font-size: 1.333rem;
        }
      }
    }
    //Einstellungen für Navbar, die bündig mit Content ist
    &.justified-with-content-mobile {
      & > .container {
        position: relative;
      }
    }
  }
}

.nav-reset();

@media (min-width: @screen-xxl-min) {
  .mobile-breakpoint-screen-xxl {
    .navbar-alignment();
    .nav-reset-desktop();
    .desktop-menu();
  }
}

@media (min-width: @screen-xl-min) {
  .mobile-breakpoint-screen-xl {
    .navbar-alignment();
    .nav-reset-desktop();
    .desktop-menu();
  }
}

@media (min-width: @screen-lg-min) {
  .mobile-breakpoint-screen-lg {
    .navbar-alignment();
    .nav-reset-desktop();
    .desktop-menu();
  }
}

@media (min-width: @screen-md-min) {
  .mobile-breakpoint-screen-md {
    .navbar-alignment();
    .nav-reset-desktop();
    .desktop-menu();
  }
}
.mobile-breakpoint-always {
  .mobile-menu();
}

@media (max-width: @screen-xxl-max) {
  .mobile-breakpoint-screen-xxl {
    .mobile-menu();
  }
}

@media (max-width: @screen-xl-max) {
  .mobile-breakpoint-screen-xl {
    .mobile-menu();
  }
}

@media (max-width: @screen-lg-max) {
  .mobile-breakpoint-screen-lg {
    .mobile-menu();
  }
}

@media (max-width: @screen-sm-max) {
  .mobile-breakpoint-screen-md {
    .mobile-menu();
  }
}

body:not(.mobile-breakpoint-always, .mobile-breakpoint-screen-xxl, .mobile-breakpoint-screen-xl, .mobile-breakpoint-screen-lg, .mobile-breakpoint-screen-md) {
  .navbar-alignment();
  .nav-reset-desktop();
  .desktop-menu();
}

.navbar.navbar-fixed-top {
  .transition(opacity 0.2s linear);
  &.trans {
    .opacity(0);
    .transition(opacity 0s linear);
  }
}

/* Suche */
.header-search-box {
  input[type="search"] {
  }
}
