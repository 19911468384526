/* CSS for nanogallery2 - www.nanogallery2.nanostudio.org */

/**************************************/
/* nanogallery2 - main container      */
/**************************************/

.nGY2 {
	box-sizing: content-box;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	-o-box-sizing: content-box;
}
 
.nGY2 .ngy2_container {
  width: 100%;
  min-width: 100px; /* to display something even if parent has display:inline-block */
	font-size: 1.0em;
	line-height: normal;
	padding: 0px;
	margin: 0 auto;
	overflow: hidden;
  visibility: visible;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}


/*******************************************/
/* NGY2 ICON FONT                          */
/* generated with http://fontello.com      */
/* PARTIALLY BASED ON FONT AWESOME         */
/*******************************************/

/* base64 encoder: http://jpillora.com/base64-encoder */

@font-face {
  font-family: 'ngy2_icon_font';
  src: url('../../fonts/nanogallery/ngy2_icon_font.woff2?62863819') format('woff2'),
       url('../../fonts/nanogallery/ngy2_icon_font.woff?62863819') format('woff');
  font-weight: normal;
  font-style: normal;
}
  
.nGY2Icon-star:before { content: '\e801'; } /* '?' */
.nGY2Icon-search:before { content: '\e800'; } /* '?' */
.nGY2Icon-star-empty:before { content: '\e802'; } /* '?' */
.nGY2Icon-video:before { content: '\e803'; } /* '?' */
.nGY2Icon-videocam:before { content: '\e804'; } /* '?' */
.nGY2Icon-picture:before { content: '\e805'; } /* '?' */
.nGY2Icon-camera:before { content: '\e806'; } /* '?' */
.nGY2Icon-camera-alt:before { content: '\e807'; } /* '?' */
.nGY2Icon-ok:before { content: '\e808'; } /* '?' */
.nGY2Icon-help:before { content: '\e80a'; } /* '?' */
.nGY2Icon-help-circled:before { content: '\e80b'; } /* '?' */
.nGY2Icon-home:before { content: '\e80e'; } /* '?' */
.nGY2Icon-link:before { content: '\e80f'; } /* '?' */
.nGY2Icon-link-ext:before { content: '\e810'; } /* '?' */
.nGY2Icon-heart:before { content: '\e811'; } /* '?' */
.nGY2Icon-ngy2_chevron-right:before { content: '\e812'; } /* '?' */
.nGY2Icon-upload:before { content: '\e814'; } /* '?' */
.nGY2Icon-reply-all:before { content: '\e815'; } /* '?' */
.nGY2Icon-export:before { content: '\e816'; } /* '?' */
.nGY2Icon-chat:before { content: '\e818'; } /* '?' */
.nGY2Icon-attention:before { content: '\e819'; } /* '?' */
.nGY2Icon-location:before { content: '\e81a'; } /* '?' */
.nGY2Icon-trash:before { content: '\e81b'; } /* '?' */
.nGY2Icon-folder-empty:before { content: '\e81c'; } /* '?' */
.nGY2Icon-folder-open-empty:before { content: '\e81d'; } /* '?' */
.nGY2Icon-menu:before { content: '\e81e'; } /* '?' */
.nGY2Icon-cog:before { content: '\e81f'; } /* '?' */
.nGY2Icon-cog-alt:before { content: '\e820'; } /* '?' */
.nGY2Icon-wrench:before { content: '\e821'; } /* '?' */
.nGY2Icon-lightbulb:before { content: '\e822'; } /* '?' */
.nGY2Icon-resize-full:before { content: '\e823'; } /* '?' */
.nGY2Icon-resize-small:before { content: '\e824'; } /* '?' */
.nGY2Icon-left-open:before { content: '\e827'; } /* '?' */
.nGY2Icon-right-open:before { content: '\e828'; } /* '?' */
.nGY2Icon-arrows-cw:before { content: '\e829'; } /* '?' */
.nGY2Icon-level-up:before { content: '\e82a'; } /* '?' */
.nGY2Icon-play:before { content: '\e82b'; } /* '?' */
.nGY2Icon-pause:before { content: '\e82c'; } /* '?' */
.nGY2Icon-ngy2_chevron-left:before { content: '\e82d'; } /* '?' */
.nGY2Icon-ellipsis-vert:before { content: '\e82e'; } /* '?' */
.nGY2Icon-toggle-off:before { content: '\e82f'; } /* '?' */
.nGY2Icon-toggle-on:before { content: '\e830'; } /* '?' */
.nGY2Icon-check:before { content: '\e831'; } /* '?' */
.nGY2Icon-check-empty:before { content: '\e832'; } /* '?' */
.nGY2Icon-rocket:before { content: '\e833'; } /* '?' */
.nGY2Icon-filter:before { content: '\e834'; } /* '?' */
.nGY2Icon-magic:before { content: '\e835'; } /* '?' */
.nGY2Icon-pinterest-squared:before { content: '\e836'; } /* '?' */
.nGY2Icon-gplus-squared:before { content: '\e837'; } /* '?' */
.nGY2Icon-facebook-squared:before { content: '\e838'; } /* '?' */
.nGY2Icon-basket:before { content: '\e839'; } /* '?' */
.nGY2Icon-ok-circled:before { content: '\e83a'; } /* '?' */
.nGY2Icon-user:before { content: '\e83b'; } /* '?' */
.nGY2Icon-ngy2_chevron_left3:before { content: '\e83c'; } /* '?' */
.nGY2Icon-ngy2_chevron_right3:before { content: '\e83d'; } /* '?' */
.nGY2Icon-zoom-out-1:before { content: '\e83e'; } /* '?' */
.nGY2Icon-ngy2_zoom_out2:before { content: '\e83f'; } /* '?' */
.nGY2Icon-ngy2_zoom_in2:before { content: '\e840'; } /* '?' */
.nGY2Icon-ngy2_share2:before { content: '\e841'; } /* '?' */
.nGY2Icon-ngy2_external2:before { content: '\e842'; } /* '?' */
.nGY2Icon-ngy2_close2:before { content: '\e843'; } /* '?' */
.nGY2Icon-ngy2_info2:before { content: '\e844'; } /* '?' */
.nGY2Icon-ngy2_chevron_up2:before { content: '\e845'; } /* '?' */
.nGY2Icon-ngy2_download2:before { content: '\e846'; } /* '?' */
.nGY2Icon-mail-alt:before { content: '\f0e0'; } /* '?' */
.nGY2Icon-circle-empty:before { content: '\f10c'; } /* '?' */
.nGY2Icon-tumblr-squared:before { content: '\f174'; } /* '?' */
.nGY2Icon-twitter-squared:before { content: '\f304'; } /* '?' */
.nGY2Icon-youtube-play:before { content: '\f16a'; } /* '?' */
.nGY2Icon-vkontakte:before { content: '\f189'; } /* '?' */


[class^="nGY2Icon-"] {
  margin-left: .2em;
	margin-right: .3em;
	font-family: "ngy2_icon_font";
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	/* width: 1em; */
	text-align: center;
	font-variant: normal;
	text-transform: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}


/**************************************/
/* NAVIGATION BAR                     */
/**************************************/

.nGY2Navigationbar {
	margin: 5px 0px;
	padding: 5px 0px;
  display: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2Navigationbar .nGY2NavigationbarItem {
	margin: 5px 2px;
  padding: 5px 8px;
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
  visibility: 'hidden';
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

/**************************************/
/* NAVIGATION BREADCRUMB              */
/**************************************/

.nGY2Navigationbar  .oneItem {
	margin: 0px 5px;
	white-space: nowrap;
	vertical-align: middle;
	display: inline-block;
}


/**************************************/
/* GALLERY                            */
/**************************************/

.nGY2 .nGY2Gallery {
	position: relative;
	text-align: center;
 	margin: 0px auto;
	overflow: hidden;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GallerySub {
  perspective: 900px;
  text-align: left;
  position: relative;
	width: 100%;
  height: 100%;
	overflow: hidden;
	display: inline-block;
  z-index: 1;
  /* touch-action: none !important; */
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}
/*
.nGY2 .nGY2GallerySub  *{
  touch-action: auto !important;
}
*/
/**************************************/
/* THUMBNAILS                         */
/**************************************/
.nGY2 .nGY2GThumbnail {
	/* no margin allowed here */
  cursor: pointer;
	border: 0px solid #000;
	/*margin: 1px;*/
	padding: 0px; /* 5 */
	background-color: #000;
	display: inline-block;
	position: absolute;   /*relative;*/
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	overflow:hidden;
}

.nGY2 .nGY2GThumbnailStack {
	/* no margin allowed here */
  background-color: #888;
  cursor: pointer;
	border: 0px solid #000;
	/*margin: 1px;*/
	padding: 0px; /* 5 */
	display: inline-block;
	position: absolute;   /*relative;*/
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	overflow:hidden;
}
.nGY2 .nGY2GThumbnailSub {
	/* no border or padding allowed here */
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	border: 0px solid #000;
	margin: 0px;
	padding: 0px;
	overflow:hidden;
}
.nGY2 .nGY2GThumbnailSubSelected{
  -webkit-transform: scale(0.90);
  -ms-transform: scale(0.90);
  transform: scale(0.90);
  /*opacity: 0.8;*/
}

.nGY2 .nGY2GThumbnailImage {
	position:relative;
  overflow:hidden;
	background:#000;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GThumbnailImg {
	/* no border or padding allowed here */
  /* use empty image in background */
  background:url('data:image/gif;base64,R0lGODlhEAAQAIAAAP///////yH5BAEKAAEALAAAAAAQABAAAAIOjI+py+0Po5y02ouzPgUAOw==') no-repeat center;
	position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto;
	display: block;
  vertical-align: bottom;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}


.nGY2 .nGY2GThumbnailAnnotationOLD_NO_MORE_USED {
	/* no padding allowed here */
  background:rgba(34, 34, 34, 0.75); /*#222;*/
	opacity: 1;
	text-align: left;
  /* top: 0; bottom: 0; left: 0; right: 0; */ 
  left: 0; right: 0;
  padding: 1px;
	position: absolute;
	min-height: 18px;
	overflow: hidden;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GThumbnailCustomLayer {
	position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow:hidden;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}


.nGY2 .nGY2GThumbnailLabel {
/*  float:left; */
  /*display: inline-block;*/
  display: block;
  background:rgba(34, 34, 34, 0.75); /*#222;*/
  /* top: 0; bottom: 0; left: 0; right: 0; */
  left: 0; right: 0;
  padding: 4px;
  margin: 0;
	position: absolute;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GThumbnailImageTitle,
.nGY2 .nGY2GThumbnailAlbumTitle {
	color:#fff;
	margin: 5px 1px 1px 1px;
  padding: 0px 0px;
  left: 0; right: 0;
  white-space: nowrap;
	overflow:hidden;
	display: inline-block;
	Text-Shadow: #000000 1px 0px 0px, #000000 1px 1px 0px, #000000 1px -1px 0px, #000000 -1px 1px 0px, #000000 -1px 0px 0px, #000000 -1px -1px 0px, #000000 0px 1px 0px, #000000 0px -1px 0px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GThumbnailDescription {
	clear:both;
	margin: 1px 1px 3px 1px;
  padding: 0px;
	color:#aaa;
  white-space: nowrap;
  left: 0; right: 0;
  font-size:0.8em;
	Text-Shadow: #000000 1px 0px 0px, #000000 1px 1px 0px, #000000 1px -1px 0px, #000000 -1px 1px 0px, #000000 -1px 0px 0px, #000000 -1px -1px 0px, #000000 0px 1px 0px, #000000 0px -1px 0px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GThumbnailIcons {
	/*margin: 5px 5px;*/
  margin: 0;
  position: absolute;
  display: inline-block;
  padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GThumbnailIconsFullThumbnail {
  font-size: 1.8em;
  color: #fff;
  Text-Shadow: #000000 1px 0px 0px, #000000 1px 1px 0px, #000000 1px -1px 0px, #000000 -1px 1px 0px, #000000 -1px 0px 0px, #000000 -1px -1px 0px, #000000 0px 1px 0px, #000000 0px -1px 0px;
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  display: inline-block;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GThumbnailIcon {
  display: inline-block;
  /*top: 0; bottom: 0; left: 0; right: 0;*/
  padding: 4px;
  margin: 0px;
	/*position: absolute;*/
  color:#fff;
  Text-Shadow: #000000 1px 0px 0px, #000000 1px 1px 0px, #000000 1px -1px 0px, #000000 -1px 1px 0px, #000000 -1px 0px 0px, #000000 -1px -1px 0px, #000000 0px 1px 0px, #000000 0px -1px 0px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2GThumbnailIconTextBadge{
  background-color: #fff;
  /*padding: 4px;*/
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
  position: relative;
	margin: 0;
  display: inline-block;
	vertical-align: middle;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.nGY2 .nGY2GThumbnailIconText{
  position: relative;
	margin: 0;
  display: inline-block;
	vertical-align: middle;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}


.nGY2 .nGY2GThumbnailAlbumUp  i{
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translateY(-50%)  translateX(-50%);
  -ms-transform: translateY(-50%)  translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.nGY2 .nGY2GThumbnailAlbumUp {
  color:#eee;
  font-size: 1.2em;
  top: 0; bottom: 0; left: 0; right: 0;
  display: table;
  width:100%;
  text-align: center;
  position: absolute;
  margin: auto;
  padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

/**************************************/
/* GALLERY BOTTOM                     */
/**************************************/

.nGY2 .nGY2GalleryBottom {
	/*color:#eee;*/
  margin: 0px;
	padding: 0px;
	text-align:center;
	position: relative;
	overflow:hidden;
	white-space: nowrap;
}

/**************************************/
/* GALLERY MORE-BUTTON                */
/**************************************/

.nGY2 .nGY2GalleryMoreButton {
	/*color:#eee;*/
  margin: 10px auto 5px auto;
	padding: 4px;
	text-align: center;
	position: relative;
  display:inline-block;
	overflow:hidden;
	white-space: nowrap;
  cursor:pointer;
}
.nGY2 .nGY2GalleryMoreButtonAnnotation {
	/*color:#eee;*/
  margin: 10px auto 5px auto;
  border: 1px solid #fff;
	padding: 8px 20px;
	text-align: center;
	position: relative;
  display:inline-block;
	overflow:hidden;
	white-space: nowrap;
}

/**************************************/
/* GALLERY PAGINATION                 */
/**************************************/

.nGY2 .nGY2GalleryPagination,
.nGY2 .nGY2GalleryPaginationDot {
	/*color:#eee;*/
  margin: 10px auto 5px auto;
	padding: 4px;
	text-align:center;
	position: relative;
	overflow:hidden;
	white-space: nowrap;
}

.nGY2 .nGY2paginationItem,
.nGY2 .nGY2paginationItemCurrentPage,
.nGY2 .nGY2PaginationPrev,
.nGY2 .nGY2PaginationNext {
	margin: 10px 4px;
  padding: 5px 8px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
  background: #111;
	white-space: nowrap;
	cursor:pointer;
	vertical-align: middle;
	display: inline-block;
}

.nGY2 .nGY2paginationItemCurrentPage{
  background: #333;
	/* text-decoration:underline; */
}

.nGY2 .nGY2paginationDot,
.nGY2 .nGY2paginationDotCurrentPage{
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  background: #444;
  border-radius: 50%;
	margin: 12px 5px;
	white-space: nowrap;
	cursor:pointer;
	vertical-align: middle;
	display: inline-block;
  opacity: 0.50;
}
.nGY2 .nGY2paginationDotCurrentPage{
  background: #fff;
  opacity: 1;
}

.nGY2 .nGY2paginationRectangle,
.nGY2 .nGY2paginationRectangleCurrentPage{
  width: 30px;
  border: 1px solid #fff;
  height: 0px;
  background: #444;
	margin: 5px 1px;
	white-space: nowrap;
	cursor: pointer;
	vertical-align: middle;
	display: inline-block;
  opacity: 0.50;
}
.nGY2 .nGY2paginationRectangleCurrentPage{
  background: #fff;
  opacity: 1;
}

/**************************************/
/* POPUP                              */
/**************************************/
.nGY2Popup {
  position: fixed;
  color: #000;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0,0,0,0.5);
/*  z-index: 2000;*/
}
.nGY2PopupContent,
.nGY2PopupContentCenter,
.nGY2PopupContentLeft,
.nGY2PopupContentRight {
  position: fixed;
  text-align: left;
  top: 50%;
  left: 50%;
  width: 50%;
  padding: 15px 30px;
  /*max-width: 630px;
  min-width: 320px;*/
  height: auto;
  background: #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.nGY2PopupContentCenter {
  text-align: center;
}

.nGY2PopupTitle {
  font-size: 1.5em;
  border-bottom: 4px solid #888;
  padding: 0 0 5px 0;
  margin-bottom: 10px;
}
.nGY2PopupCloseButton {
  font-size: 1.5em;
	cursor: pointer;
  text-align: right;
}

.nGY2PopupOneItem {
  padding : 10px 10px;
  font-size: 2em;
	cursor: pointer;
  display:inline-block;
}
.nGY2PopupOneItemText{
  padding : 10px 10px;
  font-size: 1.2em;
	cursor: pointer;
  display:block;
}
@media only screen and (max-device-width : 480px) {
  .nGY2PopupContent {
    width: 85% !important;
    padding: 5px 10px !important;
  }
}

/**************************************/
/* INFO                               */
/**************************************/
.nGY2PortInfo {
  padding: 3px !important;
  font-size: 14px !important;
  color: #FF0075 !important;
  text-align: center !important;
  z-index:0 !important;
  text-transform: lowercase !important;
  cursor:pointer !important;
}

.nGY2PortInfo a, 
.nGY2PortInfo > a:link, 
.nGY2PortInfo > a:visited,
.nGY2PortInfo a:hover,
.nGY2PortInfo a:active {
  color: #FF0075 !important;
  text-decoration: none !important;
}


/**************************************/
/* IMAGE VIEWER                       */
/**************************************/
.nGY2 .nGY2ViewerContainer {
  opacity: 0;
	position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.nGY2 .nGY2Viewer {
	display:block;
  visibility: visible;
	position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 1;
	/*font-size:1em;*/
	background-color: rgba(1, 1, 1, 0.85);
	text-align: center;
  clear: both;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.nGY2 .nGY2ViewerLogo {
  text-align: center;
  float: left;
  position: relative;
  top: 50px;
  left: 5px;
  padding: 2px;
  background: url('../../images/plugins/nanogallery/nanogallery2_logo.png') RGBA(0,0,0,0) no-repeat center;
  display: block;
  height: 74px;
  width: 147px;
}

.nGY2 .nGY2ViewerContent {
	/* background:url('loading.gif') no-repeat center; */
  min-width:40px;
  min-height:40px;
	/*display:block;*/
	/*position:relative;*/
  position:absolute;
	/*height:100%;*/
  clear:both;
	/* padding-top:5px;
	padding-left:5px;
	padding-right:5px;
	padding-bottom:5px; */
  /*
	cursor: -webkit-zoom-out;
	cursor: -moz-zoom-out;
	cursor: zoom-out;
  */
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.nGY2 .nGY2ViewerMediaPan{
  visibility: visible;
  opacity: 0;
  position: absolute;
  display: inline-block;
	padding:0px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;   /* never change this value */
  zoom: 1;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;  
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.nGY2 .nGY2ViewerMedia {
  visibility: visible;
  opacity: 1;
  will-change: transform;
  transform: translateZ(0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;    /* never change this value */
  zoom: 1;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
	background-image:     url('data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
  background-repeat:    no-repeat;
  background-position:  center;
  background-color:     #000;
	/*display:block;*/
	padding: 0px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
}

.nGY2 .nGY2ViewerContent .imgCurrent {
	cursor: pointer;
}

.nGY2 .nGY2ViewerAreaPrevious {
  transform: scaleY(1.5); 
	color: #fff; 
	display: block;
	position: absolute;
	Text-Shadow: #000000 1px 0px 0px, #000000 1px 1px 0px, #000000 1px -1px 0px, #000000 -1px 1px 0px, #000000 -1px 0px 0px, #000000 -1px -1px 0px, #000000 0px 1px 0px, #000000 0px -1px 0px; 
	/*background: rgba(0,0,0,0.2);*/
	font-size: 2em;
	top: 50%;
	/*height:20px;*/
	/*width:20px;*/
	padding: 10px 0px 10px 1px;
	margin-top: -25px;
	left: 0px;
	text-align: left;
	cursor: pointer;
	opacity: 1;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
}
.nGY2 .nGY2ViewerAreaPrevious > i {
  margin-left: 0px;
}

.nGY2 .nGY2ViewerAreaNext {
  transform: scaleY(1.5);
	color: #fff; 
	display: block;
	position: absolute;
	Text-Shadow: #000000 1px 0px 0px, #000000 1px 1px 0px, #000000 1px -1px 0px, #000000 -1px 1px 0px, #000000 -1px 0px 0px, #000000 -1px -1px 0px, #000000 0px 1px 0px, #000000 0px -1px 0px;
	/*background: rgba(0,0,0,0.2);*/
	font-size: 2em;
	top: 50%;
	/*height:20px;*/
	/*width:20px;*/
	padding: 10px 1px 10px 0px;
	margin-top: -25px;
	right: 0px;
	text-align: right;
	cursor: pointer;
	opacity: 1;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
}
.nGY2 .nGY2ViewerAreaNext > i {
  margin-right: 0px;
}

.nGY2 .toolbarContainer {
	/*bottom:0px;*/
  left: 0px;
  right: 0px;
  text-align: center;
  display: inline-block;
  position: absolute;
  width: 100%;
}

.nGY2 .toolbar {
  display: inline-block;
	vertical-align: middle;
	overflow: hidden;
  padding: 2px 0px;
	margin: 1px;
}
.nGY2 .toolbarBackground {
	background:rgba(4,4,4,0.7);
}

.nGY2 .nGY2ViewerToolsTopLeft {
	cursor:pointer;
	color: #ddd; 
	background: rgba(0,0,0,0.2);
	left: 5px;
	top: 5px;
	position:absolute;
	/*Text-Shadow: #000000 1px 0px 0px, #000000 1px 1px 0px, #000000 1px -1px 0px, #000000 -1px 1px 0px, #000000 -1px 0px 0px, #000000 -1px -1px 0px, #000000 0px 1px 0px, #000000 0px -1px 0px;*/
	opacity:1;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
}
.nGY2 .nGY2ViewerToolsTopRight {
	cursor: pointer;
	color: #ddd; 
	background: rgba(0,0,0,0.2);
	right: 5px;
	top: 5px;
	position:absolute;
	/* Text-Shadow: #000000 1px 0px 0px, #000000 1px 1px 0px, #000000 1px -1px 0px, #000000 -1px 1px 0px, #000000 -1px 0px 0px, #000000 -1px -1px 0px, #000000 0px 1px 0px, #000000 0px -1px 0px;*/
	opacity:1;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
}

.nGY2 .toolbar .ngbt {
  font-size:1.5em;
	display: table-cell;
	cursor:pointer;
	padding: 2px 5px;
	vertical-align:middle;
}

.nGY2 .ngy2viewerToolAction {
	opacity: 1;
}
.nGY2 .ngy2viewerToolAction:hover {
	opacity: 0.8;
}

.nGY2 .toolbar .pageCounter{
	display: table-cell;
	font-size:1em;
	margin:auto;
	vertical-align:middle;
	overflow: hidden;
}

.nGY2 .toolbar .label{
  padding:3px 10px 3px 10px;
	border-left: 0px solid #000;
	overflow: hidden;
	display: table-cell;
	text-align: left;
	vertical-align:middle;
	font-size:1em;
  background-color:initial;
}

.nGY2 .toolbar .label .title{
	font-size:1em;
	margin:auto;
	vertical-align:middle;
	overflow: hidden;
}
.nGY2 .toolbar .label .description{
	font-size:.8em;
	display:table-row;
	vertical-align:middle;
	overflow: hidden;
	color:#aaa;
}




/**************************************/
/* CONSOLE                            */
/**************************************/

.nGY2ConsoleParent {
  visibility:hidden;
  height:0px;
  background:#111;
  color:#e00;
  padding:0px;
  margin:2px;
}
.nGY2ConsoleParent p {
  color:#e00;
  padding:1px;
  margin:0px;
}

/**************************************/
/* AVOID TEXT SELECTION DURING DRAG   */
/**************************************/
.nGY2.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/**************************************/
/* LOADING BAR                        */
/**************************************/
.nGY2 .nanoGalleryLBarOff{
	position:relative;
  width:100%;
  visibility: hidden;
  height:2px;
  margin:0 0 1px 0;
  background-color:#556;
}
.nGY2 .nanoGalleryLBar {
	position:relative;
  width:100%;
  visibility: visible;
  height:2px;
  margin:0 0 1px 0;
  background-color:#556;
}
.nGY2 .nanoGalleryLBar > div {
	position:absolute;
	width:50px;
	height:2px;
	top:0px;
  opacity:.7;		
}
.nGY2 .nanoGalleryLBar > div:nth-child(1){
  -webkit-animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  background: #111;
}
.nGY2 .nanoGalleryLBar > div:nth-child(2){
  -webkit-animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  background: #333;
}
.nGY2 .nanoGalleryLBar > div:nth-child(3){
  -webkit-animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  background: #ccd;
}
.nGY2 .nanoGalleryLBar > div:nth-child(4){
  -webkit-animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  background: #777; 
}
.nGY2 .nanoGalleryLBar > div:nth-child(5){
  -webkit-animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
	background: #ddd;
}
@-webkit-keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}
@-moz-keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}
@-ms-keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}
@-o-keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}
@keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}