body {
  .opacity(0);
  .transition( opacity 0.2s linear );
  &.loaded {
    .opacity(100);
  }

  &.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }
}

// Grid
@media (max-width: @screen-xxs-max) {
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    width: 100%;
  }
}

@media (min-width: @screen-xs-min) {
  .container {
    max-width: 90%;
  }
}



// Link color
.teaser,
.teaser a {
  color: @text-color !important;
  &:hover {
    color: @text-color !important;
    text-decoration: none;
  }
}

.white a {
  color: @white;
}

// Globals
.img-responsive {
  width: 100%;
}

img {
  max-width: 100%;
}

.hidden-xxs {
  @media (max-width: @screen-xxs-max) {
    .responsive-invisibility();
  }
}

// Header
#page {
  h1.header-title {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    margin-bottom: 2rem !important;
    border-radius: 0.5rem;
    background: @header-title-bg;
    .opacity(80);
    text-shadow: 2px 2px #434343;
    letter-spacing: 0;
    font-weight: normal;
    @media (min-width: @screen-xs-min) {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    span {
      display: block;
    }
  }

  .header-video-caption h1.header-title {
    background: none;
  }
}

#page {
  .header-img-size-box {
    position: relative;
    .header-title {
      margin-bottom: 0 !important;
    }
    .header-title + .btn {
      margin-top: 2rem !important;
    }
    video {
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
      overflow: hidden;
      background-size: cover;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

// wird per JS eingeblendet nachdem das Element zentriert worden ist
.header-img-size-box .center-in-header {
  display: none;
}

.header-img-size-box:not(.with-caption) {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  .carousel,
  .header-box,
  .header-intro {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    .carousel-inner {
      height: 100%;
      .item {
        height: 100%;
      }
    }
  }
}

.header-img-size-box:not(.with-caption) {
  &.full-width {
    .header-intro .container,
    .header-intro .container .row,
    .header-intro .container .row > div {
      height: 100%;
    }
    .header-title {
      margin-top: 0;
      margin-bottom: 0;
      position: relative;
      top: 22%;
    }
    @media (min-width: @screen-xs-min) {
      padding-bottom: 45%;
    }
    @media (min-width: @screen-sm-min) {
      padding-bottom: 40%;
    }
    @media (min-width: @screen-md-min) {
      padding-bottom: 35%;
      .header-title {
        top: 22%;
      }
    }
    @media (min-width: @screen-lg-min) {
      padding-bottom: 30%;
    }
  }
}

// Footer
footer {
  margin-top: 4rem;
  background: @footer-bg;
  border-top: 0.25rem @footer-border-bg solid;
  position: relative;
  .footer-top {
    padding-top: 1.25rem;
    padding-bottom: 0.5rem;
    background: @footer-top-bg;
    abbr {
      text-decoration: none !important;
      border-bottom: 1px @white dotted;
    }
    @media (min-width: @screen-md-min) {
      .cta-col .btn {
        float: right !important;
      }
    }
  }
  .footer-top-address {
    h3 {
      padding-top: 0 !important;
    }
  }
  .footer-bottom {
    padding: 0.5rem 0;
    background: @footer-bottom-bg;
    .footer-copyrights-box,
    .footer-social-box {
      ul,li,p {
        margin-bottom: 0 !important;
      }
      p, a {
        line-height: 4rem !important;
        @media (max-width: @screen-sm-max) {
          line-height: 6rem !important;
        }
      }
      @media (min-width: @screen-md-min) {
        width: auto;
      }
    }
    .footer-copyrights-box {
      .ezrichtext-field p {
        text-align: center !important;
      }
      .ezrichtext-field p,
      .ezrichtext-field a {
        font-size: 1.333rem !important;
      }
      @media (max-width: @screen-sm-max) {
        .ezrichtext-field p,
        .ezrichtext-field a {
          line-height: 3rem !important;
        }
        .ezrichtext-field p {
          padding: 1.5rem 0;
        }
      }

      @media (min-width: @screen-md-min) {
        .ezrichtext-field p {
          text-align: left !important;
        }
      }
    }
    .footer-social-box {
      position: relative;
      @media (max-width: @screen-xs-min) {
        &.extraPadding {
          padding-bottom: 6rem;
        }
      }
      p {
        height: 4rem;
        @media (max-width: @screen-sm-max) {
          height: 6rem;
        }
      }
      @media (min-width: @screen-md-min) {
        float: right;
        p {
          float: right !important;
        }
      }
      a {
        padding: 0.5rem 0 0.5rem 1rem;
        display: inline-block;
        color: transparent;
        i.fa {
          display: block;
          float: left;
          font-size: 1.5rem;
          width: 3rem;
          height: 3rem;
          line-height: 3rem;
          border-radius: 5rem;
          margin-top: 0;
          padding: 0;
          color: @white;
          background: @gray;
          border-width: 1px;
          border: none;
          text-align: center;
          &:hover,
          &:focus {
            color: @brand-primary;
            background: @white;
          }
          @media (max-width: @screen-sm-max) and (min-width: 420px) {
            font-size: 2.5rem;
            width: 5rem;
            height: 5rem;
            line-height: 5rem;
          }
        }
      }
      a:first-child {
        padding-left: 0;
      }
    }
  }
  .meadow-logo-bottom {
    width: auto !important;
    max-height: 4rem;
    min-height: 3rem;
    margin-bottom: 1rem;
  }
}

.footer-top .white,
.footer-bottom .white,
.footer-top .white *,
.footer-bottom .white *{
  color: @white;
}

// Caroussel
.center-in-header {
  .btn {
    margin-bottom: 0 !important;
  }
}

.item.tmpVisible {
  display: block !important;
}

.carousel,
.header-box {
  overflow: hidden;
  margin-bottom: 0;
  &.background-delay {
    background: #000 !important;
  }
  .item {
    width: 100%;
    background-color: @body-bg;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .header-title {
      margin-top: 0;
    }
  }
  .carousel-indicators {
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    font-size: unset !important;
    margin-bottom: 2rem !important;
    height: 2rem !important;
    li {
      width: 1rem;
      height: 1rem;
      border-radius: 1rem;
      margin-bottom: 0 !important;
    }
  }
}

#page #content-section {
  .decent-carousel {
    overflow: visible;
    background: @body-bg;
    .carousel-indicators {
      position: absolute;
      bottom: 0 !important;
      left: 0;
      width: 100%;
      margin: 0 !important;
      background: @blackTrans;
      border-radius: 0 0 @border-radius-large @border-radius-large;
      li {
        margin-bottom: 0 !important;
      }
    }
    .carousel-inner {
      border-radius: @border-radius-large;
      .item {
        border-radius: @border-radius-large;
      }
    }
  }
}


.carousel-caption:not(.decent-caption),
.header-caption {
  position: absolute;
  bottom: 100px;
  top: auto;
  left: 10%;
  right: 10%;
  @media (min-width: @screen-md-min) {
    left: 16% !important;
    right: 16% !important;
  }
  .header-title-without-cta {
    margin-bottom: 1.375em;
  }
}

.header-caption {
  .header-title {
    margin-top: 0;
    margin-bottom: 1.375em;
  }
}

#page #content-section {
  .carousel-caption.decent-caption {
    left: 0;
    right: 0;
    bottom: 2rem;
    top: auto;
    padding: 0.25rem 1rem 0 1rem;
    margin-bottom: 0;
    background: @blackTrans;
    text-align: center !important;
    .ezrichtext-field p {
      text-align: center;
    }
    .ezrichtext-field p:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}

.carousel-control {
  background: none !important;
  width: 10%;
  .opacity(50);
  display: none;
  @media (min-width: @screen-xs-min) {
    display: block;
  }
  &:hover {
    .opacity(100);
  }
  .glyphicon {
    width: 4rem;
    height: 4rem;
  }
  i.fa {
    font-size: 4rem;
    line-height: 4rem;
    @media (min-width: @screen-sm-min) {
      font-size: 4rem;
      line-height: 4rem;
    }
    @media (min-width: @screen-md-min) {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  .glyphicon-chevron-left {
    left: 30%;
  }
  .glyphicon-chevron-right {
    right: 30%;
  }
  @media (min-width: @screen-sm-min) {
    .glyphicon-chevron-left {
      left: 40%;
    }
    .glyphicon-chevron-right {
      right: 40%;
    }
  }
  @media (min-width: @screen-md-min) {
    .glyphicon-chevron-left {
      left: 50%;
    }
    .glyphicon-chevron-right {
      right: 50%;
    }
  }
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
  margin-top: -2rem;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  margin-right: -2rem;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
  margin-left: -2rem;
}

.carousel-control {
  .glyphicon {
    width: 4rem;
    height: 4rem;
    &:before {
      display: none;
      content: '';
    }
  }
  i.fa {
    font-size: 4rem;
    line-height: 4rem;
  }
}

.carousel-inner {
  > .item {
    // WebKit CSS3 transforms for supported devices
    @media all and (transform-3d), (-webkit-transform-3d) {
      .backface-visibility(~'visible');
    }
  }
}

// Forms
.user-content {
  .col-md-6,
  .col-md-4 {
    width: 100%;
  }
  .col-md-offset-3,
  .col-md-offset-4 {
    margin: 0 !important;
  }
}

form {
  overflow: hidden;
  .form-group:first-of-type {
    margin-top: 0;
  }
}

.form-group {
  margin: 1em 0;
  clear: left;
}

.checkbox-group {
  label > span {
    position: relative;
    padding-left: 3rem;
    display: inline-block;
    float: left;
    top: -4rem;
    margin-bottom: -4rem;
  }
}

.full-form {
  padding-top: 2rem;
  &.no-padding {
    padding-top: 0;
  }
  .form-group {
    input.form-control,
    textarea.form-control {
      max-width: none;
    }
  }
}

@media (max-width: @screen-sm-max) {
  .form-group,
  .full-form .form-group {
    input.form-control,
    textarea.form-control {
      max-width: none;
    }
  }
}

.user-form,
.user-form-login {
  label {
    color: @text-color;
  }
  ul li {
    color: @brand-danger;
  }
}

.user-form-confirmation {
  background: @gray-lighter;
}

.user-form-confirmation h2 {
  .border-top-radius(4px);
}

// Buttons
#page {
  .btn {
    margin-bottom: 2rem;
    border: none;
    padding: 0.5rem 1rem;
  }
  .btn-lg {
    padding: 0.25rem 1rem;
    .box-sizing(border-box);
    height: 3.5rem;
    line-height: 3rem !important;
    span {
      line-height: 3rem !important;
    }
  }
  .btn-full {
    width: 100%;
  }
}

#loadMoreBtn {
  i {
    //margin-right: 0.5rem;
    width: 3rem;
  }
}

/* GMap Content */
#content-section .ezgmaplocation-field {
  height: 100%;
  border-radius: @border-radius-large !important;
  overflow: hidden !important;
  .leaflet-control-attribution,
  .leaflet-control-attribution a {
    font-size: 10px !important;
    line-height: 1.5em !important;
  }
  .leaflet-control-zoom {
    a {
      font-size: 22px !important;
      line-height: 30px !important;
    }
  }
  a.leaflet-popup-close-button {
    font-size: 16px !important;
    line-height: 14px !important;
  }
}

#content-section header .ezgmaplocation-field {
  border-radius: 0 !important;
}

#content-section .gmap-content {
  h3,p {
    text-align: left;
  }
  span.nowrap {
    white-space: nowrap;
  }
}

.gmap-full {
  padding-bottom: 100%;
  border-radius: @border-radius-large !important;
  @media (min-width: @screen-xs-min) {
    padding-bottom: 56.25%;
  }
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
  .gmap-full-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

/* Back button navbar */
.back-button-navbar {
  top: 6rem;
  background: @brand-primary;
  color: @white;
  border-bottom: 1px @brand-primary solid;
  .opacity(100);
  z-index: 1020;
  position: fixed !important;
  line-height: 4rem;
  height: 4rem;
  text-align: center;
  .box-sizing(border-box);
  .transition(top 0.1s linear);
  &.scrolled {
    top: 6rem !important;
    position: fixed !important;
    margin: 0;
    .transition(top 0.2s linear);
    @media (min-width: @screen-sm-min) {
      top: 4rem !important;
    }
  }
  &.fadeAway {
    top: -4rem !important;
    .transition(top 0.2s linear);
  }
  a {
    padding: 0 1rem !important;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: @white;
    background: @brand-primary;
    line-height: 4rem !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      background: @white;
      color: @brand-primary;
    }
  }
}
/* Back Button */
.back-button {
  padding-top: 4rem;
}
.back-button,
.back-button-navbar {
  i {
    margin-right: 0.5rem;
  }
}
/* Gallery */
@media (max-width: @screen-xs-max) {
  body .lightgallery-style {
    a.responsive {
      width: 100%;
      img {
        margin-left: 0 !important;
        margin-right: 0 !important;
        .box-shadow(none);
        width: 100%;
        height: auto;
      }
      &:hover {
        img {
          -webkit-transform: scale3d(1,1,1) !important;
          transform: scale3d(1,1,1) !important;
        }
      }
    }
  }
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  z-index: 1090;
}

.lg-sub-html {
  padding: 1rem 2rem;
  h4 {
    color: @white;
    text-align: center;
  }
  a {
    color: @white;
    text-decoration: none;
    span {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

@media only screen and (orientation: portrait) {
  .lg-video-cont.lg-has-iframe {
    max-width: 94% !important;
  }
}

/* ANSPRECHPARTNER */
section.contact-persons {
  background: @gray-lightest;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-top: 4rem;
  &.contact-person {
    background: none;
    padding-bottom: 0;
    margin-top: 0;
  }
  span {
    display: block;
    span {
      display: inline;
    }
    &.pseudo-label {
      display: inline-block;
      padding-right: 10px;
    }
  }
  h1, h3 {
    @media (min-width: @screen-sm-min) {
      padding-top: 0 !important;
    }
    span {
      display: inline;
    }
  }
  .contact-person-communication-box {
    display: table;
    .contact-person-email,
    .contact-person-phone,
    .contact-person-fax,
    .contact-person-vcard {
      display: table-row;
      span,a,i {
        display: table-cell;
        line-height: 3rem;
      }
      i {
        padding-right: 0.75rem;
      }
    }
    .contact-person-fax {
      color: @gray-deactivated;
    }
  }
  .contact-person-description {
    padding-top: 1rem;
    .eztext-field {
      margin-bottom: 0 !important;
    }
  }
}

/* Unfocus */
.unfocus {
  color: @gray-deactivated;
}

/* File icons */
.file-icon {
  display: inline-block;
  position: relative;
  top: 2px;
  background:url('../../images/icons/filetypes/file.png') left 0 bottom 1px no-repeat;
  width: 2.4em;
  height: 2.4em;
  background-size: contain;
  margin-right: 0.2em;
  &.file-icon-pdf {
    background-image: url('../../images/icons/filetypes/pdf.png');
  }
  &.file-icon-image {
    background-image: url('../../images/icons/filetypes/image.png');
  }
  &.file-icon-video {
    background-image: url('../../images/icons/filetypes/video.png');
  }
  &.file-icon-mspowerpoint,
  &.file-icon-ppt,
  &.file-icon-pptx {
    background-image: url('../../images/icons/filetypes/ppt.png');
  }
  &.file-icon-msword,
  &.file-icon-doc,
  &.file-icon-docx,
  &.file-icon-text{
    background-image: url('../../images/icons/filetypes/text.png');
  }
  &.file-icon-tar,
  &.file-icon-gz {
    background-image: url('../../images/icons/filetypes/archive.png');
  }
  &.file-icon-zip {
    background-image: url('../../images/icons/filetypes/zip.png');
  }
  &.file-icon-rar {
    background-image: url('../../images/icons/filetypes/rar.png');
  }
  &.file-icon-audio,
  &.file-icon-mp3 {
    background-image: url('../../images/icons/filetypes/sound.png');
  }
  &.file-icon-xls,
  &.file-icon-xlsx {
    background-image: url('../../images/icons/filetypes/xls.png');
  }
  &.file-icon-apk {
    background-image: url('../../images/icons/filetypes/apk.png');
  }
  &.file-icon-html {
    background-image: url('../../images/icons/filetypes/html.png');
  }
  &.file-icon-ics {
    background-image: url('../../images/icons/filetypes/ics.png');
  }
}

.embedded-inline {
  a {
    cursor: pointer;
  }
  .file-icon {
    width: 0.8em;
    height: 1em;
  }
}

#page #content-section {
  .embedded-file {
    padding: 1rem;
    background: @gray-lightest;
    .description {
      padding-top: 0.5rem;
      p:last-of-type {
        margin-bottom: 0 !important;
      }
    }
    .unfocus {
      display: inline-block;
      white-space:nowrap;
    }
  }
}

.embedded-content {
  margin: 4rem 0;
  overflow: hidden;
  clear: both;
  &.embedded-content-fb {
    max-width: 750px;
  }
  &.embedded-content-twitter {
    max-width: 870px;
  }
  &.embedded-content-loading {
    position: relative;
  }
  &.loading {
    min-height: 64px;
  }

  @media (min-width: @screen-xs-min) {
    &.align-left:not(.full),
    &.align-right:not(.full) {
      clear: none;
    }
  }

  &.embedded-content-image,
  &.embedded-content-video {
    &.align-left:not(.full),
    &.align-right:not(.full) {
      margin: 2rem 0 2rem 0;
      @media (min-width: @screen-xs-min) {
        margin: 0 0 2rem 0;
      }
      float: left;
      .img-responsive {
        width: 100% !important;
        margin: 0 !important;
      }
      &.half,
      &.third,
      &.fourth {
        width: 100%;
      }
      @media (min-width: @screen-xs-min) {
        &.half,
        &.third,
        &.fourth {
          width: ~"calc(((100% + @{grid-gutter-width}) / 2) - @{grid-gutter-width})";
          margin: 0.5rem 0;
          margin-right: @grid-gutter-width;
        }
      }
      @media (min-width: @screen-sm-min) {
        &.half {
          width: ~"calc(((100% + @{grid-gutter-width}) / 2) - @{grid-gutter-width})";
        }

        &.third {
          width: ~"calc(((100% + @{grid-gutter-width}) / 3) - @{grid-gutter-width})";
        }

        &.fourth {
          width: ~"calc(((100% + @{grid-gutter-width}) / 4) - @{grid-gutter-width})";
        }
      }
    }
    &.align-right:not(.full) {
      float: right;
      margin-right: 0;
      margin-left: @grid-gutter-width;
      @media (min-width: @screen-xs-min) {
        &.half,
        &.third,
        &.fourth {
          margin-left: @grid-gutter-width;
          margin-right: 0;
        }
      }
    }
  }
  .img-responsive {
    width: auto;
    &.full {
      width: 100%;
    }
    @media (min-width: @screen-xs-min) {
      &.half,
      &.third,
      &.fourth {
        width: ~"calc(((100% + @{grid-gutter-width}) / 2) - @{grid-gutter-width})";
      }
    }
    @media (min-width: @screen-sm-min) {
      &.half {
        width: ~"calc(((100% + @{grid-gutter-width}) / 2) - @{grid-gutter-width})";
      }
      &.third {
        width: ~"calc(((100% + @{grid-gutter-width}) / 3) - @{grid-gutter-width})";
      }
      &.fourth {
        width: ~"calc(((100% + @{grid-gutter-width}) / 4) - @{grid-gutter-width})";
      }
    }
  }
  &:not(.align-left):not(.align-right) {
    .embedded-image,
    .embedded-video {
      .img-responsive,
      .img-caption,
      .embed-responsive {
        width: 100%;
      }
    }
    @media (min-width: @screen-xs-min) {
      &.half,
      &.third,
      &.fourth {
        .embedded-image,
        .embedded-video {
          .img-responsive,
          .img-caption,
          .embed-responsive {
            width: ~"calc(((100% + @{grid-gutter-width}) / 2) - @{grid-gutter-width})";
          }
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      &.half {
        .embedded-image,
        .embedded-video {
          .img-responsive,
          .img-caption,
          .embed-responsive {
            width: ~"calc(((100% + @{grid-gutter-width}) / 2) - @{grid-gutter-width})";
          }
        }
      }
      &.third {
        .embedded-image,
        .embedded-video {
          .img-responsive,
          .img-caption,
          .embed-responsive {
            width: ~"calc(((100% + @{grid-gutter-width}) / 3) - @{grid-gutter-width})";
          }
        }
      }
      &.fourth {
        .embedded-image,
        .embedded-video {
          .img-responsive,
          .img-caption,
          .embed-responsive {
            width: ~"calc(((100% + @{grid-gutter-width}) / 4) - @{grid-gutter-width})";
          }
        }
      }
    }
  }
  &.align-center {
    .embedded-image,
    .embedded-video {
      .img-responsive,
      .img-caption,
      .embed-responsive {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }
  &.embedded-content-menu_item {
    display: none;
  }
  &.event,
  &.event-extended {
    overflow: visible;
    .multi-elem-link {
      overflow: visible;
      position: relative;
      display: block;
      .fixed-date-row {
        top: 1rem !important;
        left: ~"calc(-1 * @{grid-gutter-width} / 2)";
      }
    }
  }
}

/* IMG CAPTION */
#page #content-section {
  .img-caption {
    background: @gray-lighter;
    text-align: center;
    padding: 0.5rem;
    p {
      margin-bottom: 0 !important;
      font-style: italic !important;
    }
  }
}


/* PAGE TOP LINK */
#page {
  #page-top-link {
    position: fixed;
    bottom: 0.5rem;
    display: block !important;
    z-index: 100;
    .transition(right 0.5s);
    height: 4rem;
    width: 4.5rem;
    padding: 0 0.5rem 0 0;
    right: -0.5rem;
    border: none;
    .box-sizing(border-box);
    margin-bottom: 0;
    @media (min-width: @screen-md-min) {
      //bottom: 6rem;
    }
    @media (max-width: @screen-sm-max) {
      bottom: 1.5rem;
    }
    span {
      display: none;
    }
    &.hidden {
      right: -4.5rem;
      .transition(right 0.5s);
    }
    i {
      line-height: 4rem;
    }
  }
  &.cc-banner-active {
    #page-top-link {
      bottom: 4.5rem;
      @media (min-width: @screen-md-min) {
        //bottom: 10rem;
      }
      @media (max-width: @screen-sm-max) {
        bottom: 5.5rem;
      }
    }
  }
}

/* Recaptcha badge */
#page {
  #recaptcha-wrapper {
    clear: both;
    overflow: hidden;
    margin-bottom: 4rem;
    padding: 1rem;
    background: @gray-lighter;
    border-radius: @border-radius-large;
    @media (min-width: @screen-md-min) {
      margin-bottom: 2rem;
    }
    p {
      font-size: 1rem !important;
      line-height: 1.5rem !important;
    }
  }
}

/* CONTENT SECTION STYLES */
#content-section,
#adhoc-notification {
  //margin-bottom: 4rem;
  /* LISTEN */
  ul {
    list-style-type: square !important;
  }
  ol, ul {
    list-style-position: outside !important;
    margin-left: 0;
    padding-left: 1.2em;
  }
  .text-main {
    padding: 0;
  }
}

#page #content-section {
  .infobox {
    background: @gray-lightest;
    padding: 1.5rem 2rem !important;
    margin-bottom: 2rem !important;
    overflow: hidden;
    p:last-of-type {
      margin-bottom: 0 !important;
    }
    :last-child {
      margin-bottom: 0 !important;
    }
    #tmp-container {
      width: 100% !important;
      padding: 0 !important;
      max-width: none !important;
    }
  }
}

/* autoclear columns START */
@media (min-width:@screen-lg-min){
  .auto-clear .col-lg-1:nth-child(12n+1){clear:left;}
  .auto-clear .col-lg-2:nth-child(6n+1){clear:left;}
  .auto-clear .col-lg-3:nth-child(4n+1){clear:left;}
  .auto-clear .col-lg-4:nth-child(3n+1){clear:left;}
  .auto-clear .col-lg-6:nth-child(odd){clear:left;}
}
@media (min-width:@screen-md-min) and (max-width:@screen-md-max){
  .auto-clear .col-md-1:nth-child(12n+1){clear:left;}
  .auto-clear .col-md-2:nth-child(6n+1){clear:left;}
  .auto-clear .col-md-3:nth-child(4n+1){clear:left;}
  .auto-clear .col-md-4:nth-child(3n+1){clear:left;}
  .auto-clear .col-md-6:nth-child(odd){clear:left;}
}
@media (min-width:@screen-sm-min) and (max-width:@screen-sm-max){
  .auto-clear .col-sm-1:nth-child(12n+1){clear:left;}
  .auto-clear .col-sm-2:nth-child(6n+1){clear:left;}
  .auto-clear .col-sm-3:nth-child(4n+1){clear:left;}
  .auto-clear .col-sm-4:nth-child(3n+1){clear:left;}
  .auto-clear .col-sm-6:nth-child(odd){clear:left;}
}
@media (max-width:@screen-xs-max){
  .auto-clear .col-xs-1:nth-child(12n+1){clear:left;}
  .auto-clear .col-xs-2:nth-child(6n+1){clear:left;}
  .auto-clear .col-xs-3:nth-child(4n+1){clear:left;}
  .auto-clear .col-xs-4:nth-child(3n+1){clear:left;}
  .auto-clear .col-xs-6:nth-child(odd){clear:left;}
}
/* autoclear columns END */

/* abgerundete Bilder START */
.img-rounded-top {
  border-radius: @border-radius-large @border-radius-large 0 0;
}

.img-rounded-bottom {
  border-radius: 0 0 @border-radius-large @border-radius-large;
}
/* abgerundete Bilder END */

/* Fix aspect ratio for child-item images START */
.img-fixed-size-container {
  display: block;
  width: 100%;
  padding-top: @child-image-aspect-ratio-padding;
  background-size: cover;
  background-position: center center;
}
.lazy-loading {
  background-color: @gray-lighter;
  &.lazy-loaded {
    background-color: transparent;
  }
}
/* Fix aspect ratio for child-item images END */

/* Alignment START */
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
/* Alignment END */

/* Share this START */
.share-this-box {
  .list-inline-new>li {
    padding: 0.5rem 0;
    display: block;
    .img-responsive {
      max-width: 4rem;
      display: inline-block;
    }
  }
}
#page {
  .share-this-box {
    h6 {
      padding-top: 0 !important;
      font-size: 1.333rem !important;
      line-height: 1.5rem !important;
    }
  }
}

.share-this-box {
  padding-top: 2rem;
  .list-inline-new {
    li {
      display: inline-block !important;
    }
  }
}
/* Share this END */
/* Content relations (Homepage) START */
#content_relations section:nth-child(1) {
  background: @gray-lightest;
}
#content_relations,
#content-section .line-content,
#content-section .children-content{
  h1 {
    padding-top: 0 !important;
    margin-bottom: 1rem !important;
  }
  h2 {
    margin-bottom: 4rem !important;
  }
  h3 {
    padding-top: 0 !important;
  }
  section {
    padding: 2rem 0;
    overflow: hidden;
    &:last-child {
      padding-bottom: 0 !important;
    }
    &.gallery-section {
      padding-bottom: 2rem !important;
      .btn {
        margin-bottom: 0 !important;
      }
    }
  }
  .btn {
    margin-top: 0;
  }

  @media (max-width: @screen-xs-max) {
    .col-xs-6,
    .col-xs-12 {
      margin-bottom: 2rem !important;
      &.vgrid-mb-3 {
        margin-bottom: 3rem !important;
      }
      &.vgrid-mb-4 {
        margin-bottom: 4rem !important;
      }
      &.vgrid-mb-6 {
        margin-bottom: 6rem !important;
      }
    }
    .row.vgrid-mb-2,
    .row.vgrid-mb-4,
    .row.vgrid-mb-6{
      .col-xs-6 {
        margin-bottom: 1.5rem !important;
        &.vgrid-mb-4 {
          margin-bottom: 4rem !important;
        }
        &.vgrid-mb-6 {
          margin-bottom: 6rem !important;
        }
      }
      .col-xs-12 {
        margin-bottom: 0 !important;
        &.vgrid-mb-4 {
          margin-bottom: 4rem !important;
        }
        &.vgrid-mb-6 {
          margin-bottom: 6rem !important;
        }
      }
    }
  }
}
/* Reset for embedded content */
#content-section .embedded-content .line-content,
#content-section .embedded-content .children-content{
  @media (max-width: @screen-xs-max) {
    .col-xs-12 {
      margin-bottom: 0 !important;
      &.vgrid-mb-3 {
        margin-bottom: 0 !important;
      }
      &.vgrid-mb-4 {
        margin-bottom: 0 !important;
      }
      &.vgrid-mb-6 {
        margin-bottom: 0 !important;
      }
    }
    .row.vgrid-mb-2,
    .row.vgrid-mb-4,
    .row.vgrid-mb-6{
      .col-xs-12 {
        margin-bottom: 0 !important;
        &.vgrid-mb-4,
        &.vgrid-mb-6 {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
#content-section .line-content {
  .zoom-img-trigger > .zoom-img {
    margin-bottom: 1.5rem !important;
  }
  @media (min-width: @screen-xs-min) {
    h3 {
      font-size: 2.104rem !important;
      line-height: 2.5rem !important;
      margin: 0 0 0.5rem 0 !important;
    }
    &.highlight-content {
      h3 {
        font-size: 2.805rem !important;
        line-height: 3rem !important;
      }
    }
  }
}

#content-section.grid,
#content-section .grid {
  &.vgrid-mb-4,
  &.vgrid-mb-6,
  .vgrid-mb-4,
  .vgrid-mb-6 {
    overflow: visible !important;
  }
  @media (min-width:@screen-lg-min) {
    .teaser .ezstring-field {
      display: inline-block;
      font-size: 1.333rem !important;
      line-height: 2rem !important;
    }
  }
}
/* Content relations (Homepage) END */

/* Intro START */
#content-section > .container:first-child h1.header-text-one {
  padding-top: 3.5rem !important;
}

#page {
  #content-section {
    .intro {
      margin-bottom: 2rem;
      padding-top: 2rem;
      h1.header-text-one {
        padding-top: 1.5rem !important;
      }
    }
    .homepage-intro {
      .ezrichtext-field {
        &:last-child {
          margin-bottom: 0 !important;
        }
        & > h1:first-child {
          padding-top: 1.5rem !important;
        }
      }
    }
  }
}
/* Intro END */

/* Zoom Img START */
.zoom-img-trigger {
  .zoom-img {
    border-radius: @border-radius-large;
    overflow: hidden;
  }
  .bg-img {
    .transition(transform 0.2s linear);
  }
  &:hover {
    .bg-img {
      .transform(scale(1.25));
      .transition(transform 2s linear);
    }
  }
}

/* Zoom Img END */

/* Vertical grid START */
.line-content {
  //padding-top: 1rem;
}
.children-content {
  padding: 2rem 0 0 0;
  position: relative;
  margin-top: 3rem;
  .pseudo-border {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: @gray-lighter;
  }
  .vgrid-mb-2:last-child,
  .vgrid-mb-3:last-child,
  .vgrid-mb-4:last-child,
  .vgrid-mb-6:last-child {
    margin-bottom: 0 !important;
  }
}

.children-content,
.contact-persons:not(.contact-person) {
  .vgrid-mb-2:last-child,
  .vgrid-mb-3:last-child,
  .vgrid-mb-4:last-child,
  .vgrid-mb-6:last-child {
    margin-bottom: 0 !important;
  }
}

.vgrid-mt-4 {
  margin-top: 4rem !important;
  overflow: hidden;
}

.vgrid-mb-2 {
  margin-bottom: 2rem !important;
  overflow: hidden;
}

.vgrid-mb-3 {
  margin-bottom: 3rem !important;
  overflow: hidden;
}

.vgrid-mb-4 {
  margin-bottom: 4rem !important;
  overflow: hidden;
}

.vgrid-mb-6 {
  margin-bottom: 6rem !important;
  overflow: hidden;
}
/* Vertical grid END */

/* News Full START */
#page #content-section {
  .news-date {
    text-transform: uppercase;
    margin-bottom: 0 !important;
  }
}
.teaser-lead {
  margin-bottom: 2rem;
  font-weight: bold;
  text-align: left !important;
  .ezstring-field {
    display: block;
  }
}
/* News Full END */

/* Overflow box START */
.overflow-box {
  position: relative;
  img.img-rounded,
  .thumbnaviSlider .lSSlideOuter,
  .thumbnaviSlider .lSSlideWrapper,
  .thumbnaviSlider ul.lightSlider li .pseudo-img {
    border-radius: 0 !important;
  }
}
@media (min-width: @screen-md-min) {
  .overflow-box {
    width: 100% !important;
    left: auto !important;
    img.img-rounded {
      border-radius: @border-radius-large !important;
    }
  }
}

// Rounded corners
.img-rounded iframe {
  border-radius: @border-radius-large;
}
/* Overflow box END */

/* Full content START */
.full-content {
  img {
    display: block;
    margin-bottom: 2.5rem;
    margin-bottom: 2rem;
  }
  .embedded-content {
    img {
      margin-bottom: 0;
    }
  }
}
/* Full content END */

.teaser a > span {
  display: inline-block !important;
}

/* Cookie consent START */
#page {
  .cc-theme-custom {
    background: @brand-primary-contrast;
    padding: 0.75rem;
    font-family: @font-family-sans-serif;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    border-top: 1px @brand-primary solid;
    .box-sizing(border-box);
    width: 100% !important;
    max-width: none !important;
    top: auto;
    bottom: 0 !important;
    margin: 0;
    @media (min-width: @screen-md-min) {
      font-size: 0.888rem !important;
      line-height: 1.5rem !important;
    }
    .cc-link {
      display: none;
      .opacity(100);
      padding: 0.25rem;
    }
    .cc-btn:last-child {
      min-width: 6rem;
    }
    .cc-btn {
      font-weight: normal;
      border: none;
      height: 2.5rem !important;
      line-height: 2rem !important;
      font-size: 1.333rem !important;
      display: table-cell !important;
      @media (min-width: @screen-md-min) {
        font-size: 1rem !important;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .cc-dismiss,
    .cc-deny {
      display: none !important;
    }
  }
  &.cc-banner-active {
    //padding-bottom: 4rem;
  }
  .cc-revoke {
    display: none;
  }
  @media (max-width: @screen-xs-max) {
    .cc-theme-custom {
      flex-direction: column !important;
      -ms-flex-direction: column !important;
      -webkit-flex-direction: column !important;
      .cc-message {
        margin-bottom: 1rem !important;
      }
      .cc-compliance {
        width: 100%;
        .cc-btn+.cc-btn {
          margin-left: 0;
        }
      }
    }
  }
}
/* Cookie consent END */

/* iFrame with dynamic height START */
iframe.dynamic-height {
  border: none;
  width: 1px;
  min-width: 100%;
}
/* iFrame with dynamic height END */

/* Thumbnavi START */
#content-section {
  .thumbnaviSlider {
    .lSSlideWrapper {
      padding-bottom: @hhr-carousel-aspect-ratio;
      border-radius: @border-radius-large @border-radius-large 0 0;
    }
    .lSSlideOuter {
      border-radius: 0 0 @border-radius-large @border-radius-large;
    }
    ul.lightSlider {
      position: absolute !important;
      top: 0;
      left: 0;
      height: 100% !important;
      padding: 0;
      margin: 0 !important;
      li {
        height: 100%;
        margin: 0;
        list-style-type: none !important;
        position: relative;
        .pseudo-img {
          height: 100%;
          background-color: @white;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: @border-radius-large @border-radius-large 0 0;
        }
        .carousel-caption.decent-caption {
          bottom: 0 !important;
        }
      }
    }
    ul.lSPager {
      padding: 0;
      margin-bottom: 0 !important;
      margin-top: 4px !important;
      li {
        margin-bottom: 0 !important;
        height: 0;
        overflow: hidden !important;
        position: relative;
        background-color: @white;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        a {
          position: absolute;
          top:0;
          left:0;
          height: 100%;
          width: 100%;
          text-align: center;
          cursor: pointer;
        }
        &:hover {
          .opacity(70);
          border-radius: 0;
        }
        &.active {
          border-radius: 0;
          .opacity(100);
          a {
            cursor: default;
          }
          a:after {
            content:"\e252";
            font-family:"Glyphicons Halflings";
            position: absolute;
            color: @white;
            font-size: 1.5rem;
            line-height: 0.4;
            width: 2rem;
            margin-left: -1rem;
          }
        }
        span.img {
          margin-bottom: 0;
          width: 100% !important;
          height: auto;
          position: absolute;
          top: 0;
          left: 0;
          display: block
        }
      }
    }
    .lSAction {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      a {
        cursor: pointer;
      }
    }
  }
}

.lazy-bg-item {
  background-image: none !important;
}
/* Thumbnavi END */

/* embedded content START */
.embed-responsive-custom {
  padding-bottom: @child-image-aspect-ratio-padding !important;
}

.twitter-tweet {
  margin: 0 !important;
  width: 100% !important;
  max-width: none !important;
  min-width: 0px !important;
}

iframe.instagram-media {
  margin: 0 !important;
  min-width: 0 !important;
  max-width: none !important;
}

.medinstagram-post {
  position: relative;
  z-index: 10;
}

.fb_iframe_widget {
  margin-bottom: -7px !important;
}

.embedded-content.loaded {
  .fb-post,
  .medinstagram-post,
  .tweet-container,
  .yt-iframe {
    background: @white;
    position: relative;
  }
}
/* embedded content END */

/* helper classes e.g. for embedded content START */
.grid-display-wrapper {
  &.has-full-width {
    overflow: hidden;
  }
  .embedded-content.grid-display {
    clear: none;
  }
}
.grid-display {
  float:left;
  @media (min-width: @screen-xs-min) {
    margin-right: @grid-gutter-width !important;
  }
  &.reset {
    margin-right: 0 !important;
  }
  &.cleared {
    clear: left !important;
  }
}

.clear-display {
  clear: left;
}

.elem-rounded {
  border-radius: @border-radius-large !important;
}

.elem-rounded-fb {
  border-radius: 1.5*@border-radius-large 1.5*@border-radius-large 0 0 !important;
  overflow: hidden;
}

.fadein {
  .opacity(0);
  .transition( opacity 0.5s linear );
  &.loaded {
    .opacity(100);
  }
}

.loading-embedded-content {
  width: 100%;
  padding-bottom: 64px;
  position: absolute;
  &.deactivated {
    display: none;
  }
  .loading-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/loading01.gif');
    background-position: center center;
    background-repeat:no-repeat;
  }
}

.nowrap {
  white-space: nowrap;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
/* helper classes e.g. for embedded content END */

/* Newsletter2Go nl2go START */
.nl2go-form {
  font-size: 1.579rem !important;
  line-height: 2.5rem !important;
  label {
    line-height: 2rem !important;
  }
  .radio_naked {
    float: left;
    margin-top: 0.5rem;
    input {
      margin-bottom: 1rem !important;
    }
  }
  @media (min-width: @screen-md-min) {
    @grid-columns-min1: @grid-columns - 1;
    .form-group:nth-of-type(1),
    .form-group:nth-of-type(2),
    .form-group:nth-of-type(3),
    .form-group:nth-of-type(4) {
      width: ~"calc( ( (100% - (@{grid-columns-min1} * @{grid-gutter-width})) * (5 / @{grid-columns}) ) + (4 * @{grid-gutter-width}) )";
    }
  }
}
/* Newsletter2Go nl2go END */

#overlay-content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1020;
  background: @black;
  .opacity(0);
  .transition( opacity 0.3s linear );
  &.colored {
    .opacity(50);
  }
  &.over-nav {
    z-index: 1050;
  }
  &.event-filter,
  &.menu-overlay {
    cursor: pointer;
  }
  &.loading {
    background-image: url('../../images/loading.gif');
    background-position: center center;
    background-repeat:no-repeat;
  }
}

/* Adhoc Notification */
#adhoc-notification {
  display: none;
  position: relative;
  background: @gray-lightest;
  padding-top: 6rem;
  padding-bottom: 2rem;
  @media (min-width: @screen-md-min) {
    padding-top: 4rem;
  }
  h1 {
    padding-top: 0 !important;
  }
  #close-btn {
    display: none;
    position: absolute;
    right: 15px;
    top: 15px;
    .fa-times {
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      cursor: pointer;
      text-align: center;
      font-size: 3rem;
    }
  }
}

// Captcha
#content-section {
  .captcha-group {
    span {
      display: block;

      &.form-info {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
      }

      &.warning {
        color: @brand-danger;
      }
    }
    input {
      margin-bottom: 0.5rem !important;
    }
  }
  #captcha-img-wrapper {
    margin: 1rem 0;
    img {
      margin-bottom: 0 !important;
    }
    img.loading {
      width: auto !important;
      height: auto !important;
      margin: 1rem auto !important;
    }
  }
}

#cc-info {
  display: none !important;
}

#content-section {
  .hidden-real-size-wrapper {
    .hidden-real-size-box {
      position: relative;
      .opacity(0);
      z-index: -1;

      &.activated {
        .opacity(100);
        z-index: 1;
        margin-bottom: 0 !important;
      }
    }

    .hidden-real-size-toggler {
      text-decoration: none !important;

      .glyphicon {
        font-size: 1rem !important;
        display: inline-block;
      }
    }
  }
}

#content-section {
  .multi-elem-link {
    text-decoration: none !important;
  }
}