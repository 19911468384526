/**************************************/
/* THUMBNAILS                         */
/**************************************/
/*
.nGY2.kmuTheme .nGY2GThumbnail {
  border-radius: 6px;
}
*/

.nGY2.kmuTheme .nGY2GThumbnailLabel {
  background:rgba(34, 34, 34, 0.75); /*#222;*/
  opacity: unset !important;
}

@media all and (-ms-high-contrast:none) {
  .nGY2.kmuTheme .nGY2GThumbnailLabel {
    opacity: 0 !important;
  }
}

.nGY2 .nGY2GThumbnailImageTitle,
.nGY2 .nGY2GThumbnailAlbumTitle {
  white-space: normal;
  text-shadow: none;
  font-size: 1.333rem !important;
  line-height: 2rem !important;
}

/**************************************/
/* POPUP                              */
/**************************************/
.nGY2Popup {
  position: fixed;
  color: #000;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0,0,0,0.5);
/*  z-index: 2000;*/
}
.nGY2PopupContent,
.nGY2PopupContentCenter,
.nGY2PopupContentLeft,
.nGY2PopupContentRight {
  position: fixed;
  text-align: left;
  top: 50%;
  left: 50%;
  width: 50%;
  padding: 15px 30px;
  /*max-width: 630px;
  min-width: 320px;*/
  height: auto;
  background: #fff;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.nGY2PopupContentCenter {
  text-align: center;
}

.nGY2PopupTitle {
  font-size: 1.5em;
  border-bottom: 4px solid #888;
  padding: 0 0 5px 0;
  margin-bottom: 10px;
}
.nGY2PopupCloseButton {
  font-size: 1.5em;
	cursor: pointer;
  text-align: right;
}

.nGY2PopupOneItem {
  padding : 10px 10px;
  font-size: 2em;
	cursor: pointer;
  display:inline-block;
}
.nGY2PopupOneItemText{
  padding : 10px 10px;
  font-size: 1.2em;
	cursor: pointer;
  display:block;
}
@media only screen and (max-device-width : 480px) {
  .nGY2PopupContent {
    width: 85% !important;
    padding: 5px 10px !important;
  }
}

/**************************************/
/* INFO                               */
/**************************************/
.nGY2PortInfo {
  padding: 3px !important;
  font-size: 14px !important;
  color: #FF0075 !important;
  text-align: center !important;
  z-index:0 !important;
  text-transform: lowercase !important;
  cursor:pointer !important;
}

.nGY2PortInfo a, 
.nGY2PortInfo > a:link, 
.nGY2PortInfo > a:visited,
.nGY2PortInfo a:hover,
.nGY2PortInfo a:active {
  color: #FF0075 !important;
  text-decoration: none !important;
}


/**************************************/
/* IMAGE VIEWER                       */
/**************************************/
.nGY2 .nGY2ViewerContainer {
  opacity: 0;
	position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.nGY2 .nGY2Viewer {
	display:block;
  visibility: visible;
	position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 1;
	/*font-size:1em;*/
	background-color: rgba(1, 1, 1, 0.85);
	text-align: center;
  clear: both;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &.toolbarMinimized {
    .toolbarContainer {
      background: none !important;
    }
  }
}

.nGY2 .nGY2ViewerContent {
	/* background:url('loading.gif') no-repeat center; */
  min-width:40px;
  min-height:40px;
	/*display:block;*/
	/*position:relative;*/
  position:absolute;
	/*height:100%;*/
  clear:both;
	/* padding-top:5px;
	padding-left:5px;
	padding-right:5px;
	padding-bottom:5px; */
  /*
	cursor: -webkit-zoom-out;
	cursor: -moz-zoom-out;
	cursor: zoom-out;
  */
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
  img {
    max-width: none !important;
    max-height: none !important;
  }
}

.nGY2 .toolbarContainer {
  &.show-desc {
    height: auto !important;
    .toolbar .label .description {
      display: table-row !important;
    }
    .toolbar .label .title .desc-toggler {
      margin-bottom: 0.5rem !important;
    }
  }
}

.nGY2 .toolbar .ngbt {
  vertical-align: bottom !important;
}

.nGY2 .toolbar .label {
  //.opacity(100);
  display: block !important;
}

@media (min-width: @screen-md-min) {
  .nGY2 .toolbar .label {
    display: table-cell !important;
  }
}

.nGY2 .toolbar .label .title{
  font-weight:normal;
  font-size: 1.579rem !important;
  line-height: 2.5rem !important;
  .desc-toggler {
    margin-bottom: 0 !important;
    span {
      margin-left: 5px;
    }
  }
}
.nGY2 .toolbar .label .description{
  font-size: 1.184rem !important;
  line-height: 1.5rem !important;
  font-weight:normal;
  color: @white !important;
  white-space: normal;
  display: none !important;
}

.nGY2 .toolbar .label .description para {
  font-size: 1.184rem !important;
  line-height: 1.5rem !important;
  font-weight:normal;
  color: @white !important;
  white-space: normal;
  padding: 0 0.5rem 0 0;
  display: block;
  margin-bottom: 0.5rem !important;
}




/**************************************/
/* CONSOLE                            */
/**************************************/

.nGY2ConsoleParent {
  visibility:hidden;
  height:0px;
  background:#111;
  color:#e00;
  padding:0px;
  margin:2px;
}
.nGY2ConsoleParent p {
  color:#e00;
  padding:1px;
  margin:0px;
}

/**************************************/
/* AVOID TEXT SELECTION DURING DRAG   */
/**************************************/
.nGY2.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/**************************************/
/* LOADING BAR                        */
/**************************************/
.nGY2 .nanoGalleryLBarOff{
	position:relative;
  width:100%;
  visibility: hidden;
  height:2px;
  margin:0 0 1px 0;
  background-color:#556;
}
.nGY2 .nanoGalleryLBar {
	position:relative;
  width:100%;
  visibility: visible;
  height:2px;
  margin:0 0 1px 0;
  background-color:#556;
}
.nGY2 .nanoGalleryLBar > div {
	position:absolute;
	width:50px;
	height:2px;
	top:0px;
  opacity:.7;		
}
.nGY2 .nanoGalleryLBar > div:nth-child(1){
  -webkit-animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -.2s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  background: #111;
}
.nGY2 .nanoGalleryLBar > div:nth-child(2){
  -webkit-animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -.4s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  background: #333;
}
.nGY2 .nanoGalleryLBar > div:nth-child(3){
  -webkit-animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -.6s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  background: #ccd;
}
.nGY2 .nanoGalleryLBar > div:nth-child(4){
  -webkit-animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -.8s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  background: #777; 
}
.nGY2 .nanoGalleryLBar > div:nth-child(5){
  -webkit-animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -moz-animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -ms-animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  -o-animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
  animation: nanoGalleryLBarAnim 2s -1s infinite  cubic-bezier(0, 0.6, 0.9, 0.0);
	background: #ddd;
}
@-webkit-keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}
@-moz-keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}
@-ms-keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}
@-o-keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}
@keyframes nanoGalleryLBarAnim {
	0%	{ left:10%;	}
	100%{ left:90%;	}
}

/* LOAD MORE BUTTON */
.nGY2 .nGY2GalleryMoreButton {
  padding: 0;
  display: block;
  margin: 2rem 0 0 0;
}

.nanogallery_gallerytheme_dark_nanoGallery .nGY2GalleryMoreButtonAnnotation {
  background: @brand-primary !important;
  &:hover {
    background-color: darken(@brand-primary, 10%) !important;
    border-color: darken(@brand-primary, 12%) !important;
  }
}