#content-section.event-calendar,
#content-section .event,
#content-section .event_extended {
  .line-content {
    padding-top: 2rem;
    .row.vgrid-mb-6 {
      overflow: visible;
      position: relative;
    }
    @media (max-width: @screen-xs-max) {
      .row.vgrid-mb-6 {
        .col-xs-12 .zoom-img {
          margin-bottom: 2rem !important;
        }
      }
    }
  }
  span.tag-label {
    text-transform: uppercase;
    padding: 0;
    line-height: 2rem !important;
    font-weight: normal;
    display: block;
    color: @text-color;
    text-decoration: none !important;
  }
  h3.header-text-one.event {
    margin: 0 0 0.5rem 0 !important;
  }
  span.tag-label + h3.header-text-one.event {
    margin: 1rem 0 0.5rem 0 !important;
  }
  .event-date {
    padding: 0;
    line-height: 2.5rem !important;
    display: block;
    font-family: Khand, "Open Sans", serif;
    color: @text-color;
    text-decoration: none !important;
    font-size: 2.104rem !important;
    color: @text-color;
  }
  .teaser {
    padding-top: 1rem;
    position: relative;
    color: @text-color;
  }

  .fixed-date-row {
    position: absolute;
    z-index: 10;
    top: -1rem;
    //border-top: 2px @brand-primary solid;
    .box-sizing(border-box);
    span {
      line-height: 4rem !important;
      color: @brand-primary-contrast !important;
      font-family: Khand, "Open Sans", serif;
      font-size: 2.805rem !important;

      display: inline-block;
      padding: 0 1rem;
      background-color: @brand-primary !important;
      border-radius: 0 @border-radius-large @border-radius-large 0;

      @media (min-width:@screen-xs-min){
        border-radius: @border-radius-large;
      }

      @media (min-width:@screen-sm-min){
        font-size: 2.369rem !important;
        line-height: 3.5rem !important;
      }
    }
  }

  span.address-icon,
  span.date-icon,
  span.map-icon{
    position: absolute;
    width: 2rem;
    display: block !important;
    left: 0;
    top: 1rem;
    svg {
      width: 100%;
      height: 2.5rem;
    }
  }

  span.date-icon{
    top: 0.75rem;
  }

  .event-info {
    padding-top: 1rem;
    padding-left: 3rem;
    position: relative;
    color: @text-color;
    &.standalone {
      margin-top: 1rem;
      a {
        text-decoration: none !important;
      }
    }
    .eztext-field {
      margin-bottom: 0 !important;
    }
  }

  .zoom-img {
    svg {
      position: absolute;
      top: 25%;
      width: 100%;
      height: 50%;
    }
  }

  @media (max-width:@screen-xs-max){
    .col-sm-offset-6.extra-padding {
      padding-top: 4rem;
    }
  }

  .teaser-lead {
    padding-top: 2rem !important;
  }

  .hidden-real-size-box {
    .header-img-size-box {
      margin-top: 1rem;
      margin-bottom: 2rem;
      border-radius: @border-radius-large !important;
    }
  }

  &.grid {
    .fixed-date-row {
      left: 0;
    }
    @media (min-width:@screen-lg-min){
      .fixed-date-row span {
        font-size: 1.777rem !important;
        line-height: 3rem !important;
      }
      span.tag-label {
        font-size: 1.184rem !important;
      }
      .teaser .ezstring-field {
        font-size: 1.333rem !important;
        line-height: 2rem !important;
        display: inline-block;
      }
      .event-info {
        padding-top: 0.5rem;
        padding-left: 2rem;
        font-size: 1.333rem !important;
        .date-icon,
        .address-icon {
          width: 1.5rem !important;
          top: 0.25rem !important;
          font-size: 1.333rem !important;
          line-height: 2rem !important;
        }
        .address-icon {
          top: 0 !important;
        }
        .eztext-field {
          font-size: 1.184rem !important;
          line-height: 1.5rem !important;
        }
        &.standalone {
          a {
            font-size: 1.333rem !important;
          }
          .date-icon {
            top: 0.5rem !important;
          }
        }
      }
    }

    @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
      .event-date {
        font-size: 1.579rem !important;
      }
    }

  }

  &.full {
    .tag-label {
      margin-bottom: 1.5rem;
      margin-top: 3rem;
    }
  }

  #event-filter-btn,
  #event-filter-canvas-close-btn {
    position: fixed;
    top: 20%;
    display: block !important;
    z-index: 100;
    -webkit-transition: right 0.5s, width 0.3s;
    -moz-transition: right 0.5s, width 0.3s;
    -ms-transition: right 0.5s, width 0.3s;
    -o-transition: right 0.5s, width 0.3s;
    transition: right 0.5s, width 0.3s;
    height: 4rem;
    width: 4.5rem;
    padding: 0 0.5rem 0 0;
    right: -0.5rem;
    border: none;
    text-align: left !important;
    .box-sizing(border-box);
    margin-bottom: 0 !important;
    &.hidden {
      right: -4.5rem;
      -webkit-transition: right 0.5s, width 0.3s;
      -moz-transition: right 0.5s, width 0.3s;
      -ms-transition: right 0.5s, width 0.3s;
      -o-transition: right 0.5s, width 0.3s;
      transition: right 0.5s, width 0.3s;
    }
    &.hover,
    &.fixed {
      -webkit-transition: right 0.5s, width 0.3s;
      -moz-transition: right 0.5s, width 0.3s;
      -ms-transition: right 0.5s, width 0.3s;
      -o-transition: right 0.5s, width 0.3s;
      transition: right 0.5s, width 0.3s;
    }
    &.info-activated {
      -webkit-transition: right 0.5s, width 0.3s;
      -moz-transition: right 0.5s, width 0.3s;
      -ms-transition: right 0.5s, width 0.3s;
      -o-transition: right 0.5s, width 0.3s;
      transition: right 0.5s, width 0.3s;
    }
    i {
      line-height: 4rem;
      width: 4rem;
      text-align: center;
    }
    span {
      padding-right: 1rem;
    }
  }

  #event-filter-canvas {
    background: @white;
    position: fixed;
    height: 100vh;
    width: @screen-xs-min;
    max-width: 80%;
    top: 0;
    right: -@screen-sm-min;
    z-index: 1100;
    .transition(right 0.3s);
    .box-sizing(border-box);
    padding-top: 16rem;
    @media (min-width:@screen-sm-min){
      padding-top: 13rem;
    }
    &.opened {
      right: 0;
      .transition(right 0.3s);
      #event-filter-canvas-close-btn {
        top: 0 !important;
        .transition(top 0.3s);
      }
    }

    #event-filter-canvas-close-btn {
      padding: 0;
      border-radius: 0 0 0 @border-radius-large !important;
      color: @text-color;
      height: 4rem !important;
      width: 4rem;
      float: right;
      position: absolute;
      .transition(top 0.3s);
      .box-shadow(none);
      top: 0 !important;
      left: -4rem;
      i {
        line-height: 4rem;
        width: 4rem;
        color: @white;
      }
    }

    #filter-btn-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: @gray-lighter;
      height: 16rem;
      box-sizing: border-box;
      padding: 2rem 2rem 0 2rem;
      @media (min-width:@screen-sm-min){
        height: 13rem;
      }
      .btn {
        .opacity(100);
        &.disabled,
        &[disabled] {
          .opacity(30);
        }
      }
    }

    .btn {
      clear: both;
      text-align: left;
      width: 100%;
      border-radius: 0 !important;
      height: 5rem !important;
      @media (min-width:@screen-sm-min){
        height: 3.5rem !important;
      }
      i {
        margin-right: 1rem;
      }
    }

    #event-filter-wrapper {
      clear: both;
      padding: 0 2rem;
      form {
        padding-top: 2rem;
      }
      &.scrollable {
        overflow: scroll;
        height: 100%;
        form {
          padding-bottom: 2rem;
        }
      }
      h5 {
        margin: 0 !important;
        padding: 0rem 0 2rem 0 !important;
        @media (min-width:@screen-sm-min) {
          padding: 0rem 0 1.5rem 0 !important;
        }
        position: relative;
        span {
          font-size: 1.333rem !important;
          line-height: 3rem !important;
          @media (min-width:@screen-sm-min) {
            font-size: 1rem !important;
            line-height: 2.5rem !important;
          }
          position: absolute;
          bottom: -0.25rem;
          left: 0px;
        }
      }

      .form-group {
        margin-bottom: 0 !important;
      }

    }
    #event-filter-canvas-overlay {
      display: none;
      .opacity(0);
      .transition( opacity 0.3s linear );
    }
    &.loading {
      #event-filter-canvas-overlay {
        width: @screen-xs-min;
        max-width: 80%;
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1020;
        background: @black;
        .opacity(50);
        .transition( opacity 0.3s linear );
        /*
        background-image: url('../../images/loading.gif');
        background-position: center 5rem;
        background-repeat:no-repeat;
        @media (min-width:@screen-sm-min){
          background-position: center 4.5rem;
        }
        */
      }
    }
  }
}
