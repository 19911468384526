/* DO NOT OVERRIDE THIS */
/* ################################# */
.kmuIcons {
  &:extend(.fa all);
  .fa-icon();

}
/* ################################# */

@font-face {
  font-family: 'KMUIcons';
  src:  url('../../fonts/KMUIcons/KMUIcons.eot');
  src:  url('../../fonts/KMUIcons/KMUIcons.eot#iefix') format('embedded-opentype'),
  url('../../fonts/KMUIcons/KMUIcons.ttf') format('truetype'),
  url('../../fonts/KMUIcons/KMUIcons.woff') format('woff'),
  url('../../fonts/KMUIcons/KMUIcons.svg#KMUIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="kmuIcons-"], [class*=" kmuIcons-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'KMUIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kmuIcons-troisdorf-city:before {
  content: "\e900";
}