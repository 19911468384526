header:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-image: linear-gradient(top bottom, rgba(255,255,255,0.2) 0%, rgba(0,0,0,0.5) 100%);
}

.header-intro {
    background-attachment: scroll;
    background-size: cover;
    text-align: center;
    background-position: center center;
}

// Pagination
#content-section {
    .pagination {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }

    .pagination > li {
        display: inline-block;
        float: left;
        margin: 0 !important;
    }

    .pagination > li span,
    .pagination > li > a {
        padding: 0 !important;
        text-decoration: none;
        border: none;
        border-radius: 4rem;
        margin: 0 0.5rem;
        line-height: 4rem !important;
        height: 4rem !important;
        width: 4rem !important;
    }

    .pagination > li.prev,
    .pagination > li.next {
        &.disabled {
            span i {
                color: lighten(@gray-base, 85%);
            }
        }
    }

    .pagination > li.prev {
        & > a,
        & > span {
            margin-left: 0 !important;
        }
    }

    .pagination > li.next {
        & > a,
        & > span {
            margin-right: 0 !important;
        }
    }

    .pagination > .active > a,
    .pagination > .active > a:focus,
    .pagination > .active > a:hover,
    .pagination > .active > span,
    .pagination > .active > span:focus,
    .pagination > .active > span:hover {
        z-index: 2;
        cursor: default;
    }
}

.list-inline-new {
    padding-left: 0 !important;
    list-style: none;
}

.list-inline-new > li {
    padding: 10px 0;
    display: inline-block;
}


.center-text {
    text-align: center;
}


.borderline {
    border-bottom: 1px solid @text-color;
    padding-bottom: 1rem;
}


.aligned {
    display: inline-block;
}

.border-bottom {
    border-bottom: 1px solid @text-color;
}

.strong {
    font-weight: bold;
}

ul {
    list-style: none outside none;
    padding-left: 0;
    margin: 0;
}

#lightgallery img {
    margin: 0;
    border: none;
    box-shadow: none;
    &.img-responsive {
        width: auto !important;
    }
}

#lightgallery a > img {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
       -moz-transition: -moz-transform 0.15s ease 0s;
         -o-transition: -o-transform 0.15s ease 0s;
            transition: transform 0.15s ease 0s;
     -webkit-transform: scale3d(1, 1, 1);
             transform: scale3d(1, 1, 1);
}

#lightgallery a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
}

/* user menu */

*:focus {
    outline: none;
}



/* registration and login forms */
.user-form,
.user-form-login,
.user-form-confirmation{
    background: none;
    border-radius: 0;
    margin-top: 7rem;
    margin-bottom: 3rem;
    padding: 0 2rem;
    @media (max-width: @screen-sm-max) {
        margin: 0;
        padding: 0;
    }
    .btn {
        @media (max-width: @screen-sm-max) {
            max-width: 30rem;
        }
    }
}

.row.confirmation-label {
    font-size: 1.579rem !important;
    line-height: 2.5rem !important;
    margin: 1rem 0;
}

.user-form label:after {
    content: '*';
}

.error-message {
    color: #d03146;
    font-weight: bold;
    margin-bottom: 10px;
}
