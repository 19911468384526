//
// Typography
// --------------------------------------------------

// Headings
// -------------------------
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: @fontHeadlineDefault;
}

h1, .h1 {
  font-family: @fontHeadlineH1;
}

h2, .h2 {
  font-family: @fontHeadlineH2;
}

h3, .h3 {
  font-family: @fontHeadlineH3;
}

h4, .h4 {
  font-family: @fontHeadlineH4;
}

h5, .h5 {
  font-family: @fontHeadlineH5;
}

h6, .h6 {
  font-family: @fontHeadlineH6;
}


// Font sizes
@fontVWSize: 0.3125vmin;
html {
  font-size: ~"calc(@{fontVWSize} + 10px)";
  font-size: 10px;
  line-height: 1.5;

  .no-break();
}

@media (min-width: @screen-xs-min) {
  html {
    font-size: 10px;
  }
}

@media (min-width: @screen-sm-min) {
  html {
    font-size: 12px;
  }
}

@media (min-width: @screen-md-min) {
  html {
    font-size: 12px;
  }
}

@media (min-width: @screen-lg-min) {
  html {
    font-size: 14px;
  }
}

@media (min-width: @screen-full-hd-min) {
  html {
    font-size: 16px;
  }
}

// http://www.modularscale.com/?20,10&px&1.333
#content-section,
#adhoc-notification,
footer,
.header-search-box,
.user-content,
.header-caption,
.contact-persons,
.lg-sub-html,
.nanogallery_gallerytheme_custom_nanoGallery {
  h1, .h1 {
    font-size: 3.739rem !important;
    line-height: 4rem !important;
    margin: 0 0 2rem 0 !important;
    padding: 5rem 0 0 0 !important;
    &.header-title {
      padding: 3rem 0 !important;
    }
  }

  h2, .h2 {
    font-size: 3.157rem !important;
    line-height: 3.5rem !important;
    margin: 0 0 0.5rem 0 !important;
    padding: 3rem 0 0 0 !important;
    &.border-bottom {
      margin-bottom: 2rem !important;
    }
  }

  h3, .h3 {
    font-size: 2.805rem !important;
    line-height: 3rem !important;
    margin: 0 0 0.5rem 0 !important;
    padding: 2rem 0 0 0 !important;
    &.border-bottom {
      margin-bottom: 2rem !important;
    }
  }

  h4, .h4 {
    font-size: 2.369rem !important;
    line-height: 3rem !important;
    margin: 0 0 0.5rem 0 !important;
    padding: 2rem 0 0 0 !important;
    font-weight: 500 !important;
    &.border-bottom {
      margin-bottom: 2rem !important;
    }
  }
  h5, .h5 {
    font-size: 2.104rem !important;
    line-height: 2.5rem !important;
    margin: 0 0 0.5rem 0 !important;
    padding: 2rem 0 0 0 !important;
    &.border-bottom {
      margin-bottom: 2rem !important;
    }
  }
  h6, .h6 {
    font-size: 1.777rem !important;
    line-height: 2.5rem !important;
    margin: 0 0 0.5rem 0 !important;
    padding: 2rem 0 0 0 !important;
    &.border-bottom {
      margin-bottom: 2rem !important;
    }
  }

  h1 span, .h1 span, h1 a, .h1 a,
  h2 span, .h2 span, h2 a, .h2 a,
  h3 span, .h3 span, h3 a, .h3 a,
  h4 span, .h4 span, h4 a, .h4 a,
  h5 span, .h5 span, h5 a, .h5 a,
  h6 span, .h6 span, h6 a, .h6 a {
    font-size: inherit !important;
    line-height: inherit !important;
    margin: 0 !important;
    padding: 0 !important;
    text-decoration: none !important;
  }

  p, para, li, a, span, label, .eztext-field {
    font-size: 1.579rem !important;
    line-height: 2.5rem !important;
  }

  .img-caption {
    p, para, li, a, span, label, .eztext-field {
      //font-size: 1.333rem !important;
      //line-height: 2rem !important;
      font-size: 1rem !important;
      line-height: 1.5rem !important;
    }
  }

  label {
    line-height: 2rem !important;
    margin-bottom: 0.5rem !important;
  }

  legend, .legend,
  legend a, .legend a {
    font-size: 1.184rem !important;
    line-height: 1.5rem !important;
    margin: 1rem 0;
  }

  ol, ul {
    margin: 0 0 1rem 0 !important;
  }

  li {
    margin: 0 0 1rem 0 !important;
  }

  p, para, .eztext-field {
    margin: 0 0 1rem 0 !important;
  }

  ul.list-inline-new {
    li,
    li a{
      margin-bottom: 0 !important;
    }
  }

  .form-group {
    margin: 0 0 2rem 0;
    padding: 0;
  }

  .text-main {
    ol, ul, p {
      margin-bottom: 2rem !important;
    }
    .embedded-content {
      &.grid-display {
        @media (min-width: @screen-xs-min) {
          margin-right: @grid-gutter-width !important;
        }
        &.reset {
          margin-right: 0 !important;
        }
      }
    }
    //margin-bottom: -1rem;
  }

  .form-group input,
  .form-group textarea,
  input,
  input[type="text"],
  input[type="search"],
  textarea,
  select {
    margin-bottom: 2rem !important;
    width: 100%;
    display: block;
    clear: both;
    color: @text-color;
    .box-sizing(border-box);
    .box-shadow(none);
    line-height: 5rem !important;
    font-size: 1.579rem !important;
    padding: 0 1rem !important;
    border: none !important;
    border-bottom: 1px @formBorderColor solid !important;
    border-radius: 0 !important;
    background: @gray-lighter !important;
    max-width: none !important;
    @media (min-width:@screen-sm-min){
      line-height: 3.5rem !important;
    }
    &.warning {
      border-color: @brand-danger !important;
    }
    &:focus {
      background: @gray-lightest !important;
    }
  }

  select {
    height: 5rem !important;
    padding: 0.25rem 1rem !important;
    @media (min-width:@screen-sm-min){
      height: 3.5rem !important;
    }
    option {
      background: @white;
    }
  }

  input[type="text"]:-webkit-autofill,
  input[type="text"]:-webkit-autofill:hover,
  input[type="text"]:-webkit-autofill:focus,
  input[type="search"]:-webkit-autofill,
  input[type="search"]:-webkit-autofill:hover,
  input[type="search"]:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px @gray-lightest inset !important;
  }

  input[type="text"]:-webkit-autofill:focus,
  input[type="search"]:-webkit-autofill:focus,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px @gray-lighter inset !important;
  }

  .form-group input[type=checkbox],
  .form-group input[type=radio],
  input[type=checkbox],
  input[type=radio] {
    width: 2rem;
    height: 2rem;
    float: left;
    clear: left;
    margin-top: 0 !important;
    margin-right: 0.5rem;
    margin-bottom: 1rem !important;
    &:last-of-type {
      margin-bottom: 2rem !important;
    }
    & + label {
      float: left;
    }
    @media (max-width: @screen-sm-max) {
      max-width: 1.5rem;
    }
  }

  .input-with-clear-btn {
    position: relative;
    i {
      color: @gray-light;
      position: absolute;
      top: 0rem;
      right: 0rem;
      font-size: 2rem;
      cursor: pointer;
      padding: 1.5rem;
      @media (min-width:@screen-sm-min) {
        font-size: 1.5rem;
        padding: 1rem;
      }
    }
  }

  .form-group textarea,
  textarea {
    height: 11rem;
    line-height: 2.5rem !important;
    padding-top: 1.25rem !important;
    @media (min-width:@screen-sm-min){
      padding-top: 0.5rem !important;
    }
  }

  .form-group input,
  .form-group textarea {
    margin-bottom: 0;
  }

  label {
    color: @text-color;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .checkbox label {
    padding-left: 2.5rem;
  }

  .checkbox input[type="checkbox"],
  .checkbox-inline input[type="checkbox"] {
    margin-left: -2.5rem;
  }
}

footer {
  h3 {
    font-size: 2.104rem !important;
    line-height: 2.5rem !important;
    margin: 0 0 0.5rem 0 !important;
    padding: 1rem 0 0 0 !important;
  }

  @media (min-width: @screen-xs-min) {
    h3 {
      font-size: 1.777rem !important;
      line-height: 2rem !important;
    }
  }

  h1 span, .h1 span, h1 a, .h1 a,
  h2 span, .h2 span, h2 a, .h2 a,
  h3 span, .h3 span, h3 a, .h3 a,
  h4 span, .h4 span, h4 a, .h4 a,
  h5 span, .h5 span, h5 a, .h5 a,
  h6 span, .h6 span, h6 a, .h6 a {
    font-size: inherit !important;
    line-height: inherit !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  p, li, a, span,
  .grid-btn,
  .btn,
  .btn span {
    line-height: 2rem !important;
  }
  @media (min-width: @screen-xs-min) {
    p, li, a, span {
      font-size: 1.333rem !important;
      line-height: 1.5rem !important;
    }

    .grid-btn {
      font-size: 1.333rem !important;
      line-height: 1.5rem !important;
    }

    .btn,
    .btn span {
      font-size: 1.333rem !important;
      line-height: 1.5rem !important;
    }
  }
}

.grid-btn {
  font-size: 1.579rem !important;
  line-height: 2rem !important;
  display: block;
  float: left;
  .fa-right {
    margin-left: 0.5rem;
  }
}

.btn,
.btn span {
  font-size: 1.579rem !important;
  line-height: 2rem !important;
}

.text-main,
.intro,
.ezrichtext-field,
.teaser-lead {
  width: 760px;
  width: 100%;
  text-align: left;
  p, li {
    //text-align: justify;
    text-align: left;
  }
}

h1, .h1, h1 a, .h1 a {
  color: @text-color !important;
}

h1.header-title, .h1.header-title {
  color: @header-title-color !important;
}

#page {
  h1.header-title,
  .h1.header-title {
    color: @header-title-color !important;
    font-family: @fontHeadlineH1Buehne !important;
    font-size: 4.209rem !important;
    line-height: 4.5rem !important;
  }
}