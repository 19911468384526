h1, .h1, h1 a, .h1 a {
  color: @text-color !important;
}

h1.header-title, .h1.header-title {
  color: @white !important;
}

/*
h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: @brand-primary;
}
*/