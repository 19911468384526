/*

Regulierung der Höhe von Header-Image und Header-Bühne

bis @screen-md-min (992px):
fixe Höhe von @mobile-fix-height (550px)

zwischen @screen-md-min (992px) und @screen-full-hd-min (1920px):
Höhe dynamisch im Verhältnis 16:9 zur Breite

ab @screen-full-hd-min (1920px):
fixe Höhe von 1080px;

*/

// Carousel
.carousel:not(.decent-carousel),
.header-box {
  height: @hhr-header-fix-height-mobile;
  .item {
    height: 100%;
  }
  .carousel-inner {
    height: 100%;
  }
}

@media (min-width: @hhr-breakpoint-mobile) {
  .carousel:not(.decent-carousel),
  .header-box {
    //height: auto;
    //padding-top: @hhr-aspect-ratio;
    height: 100%;
    padding-top: 0;
    @media (min-width: @hhr-breakpoint-desktop) {
      //padding-top: @hhr-header-fix-height-desktop;
    }
    .carousel-inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
    .item {
      height: 100%;
    }
    .carousel-caption:not(.decent-caption),
    .header-caption {
      bottom: 0;
      padding-bottom: 20%;
      @media (min-width: @hhr-breakpoint-desktop) {
        bottom: @hhr-caption-bottom-position-desktop;
        padding-bottom: 0;
      }
    }
  }
}

// Header images without caption
#page {
  .header-img-size-box:not(.has-carousel).full-width {
    padding-bottom: @hhr-header-fix-height-mobile;
    @media (min-width: @hhr-breakpoint-mobile) {
      padding-bottom: @hhr-aspect-ratio;
    }
    @media (min-width: @hhr-breakpoint-desktop) {
      padding-bottom: @hhr-header-fix-height-desktop;
    }
  }

  .header-img-size-box {
    padding-bottom: @hhr-header-fix-height-mobile;
    @media (min-width: @hhr-breakpoint-mobile) {
      padding-bottom: @hhr-aspect-ratio;
    }
    @media (min-width: @hhr-breakpoint-desktop) {
      padding-bottom: @hhr-header-fix-height-desktop;
    }
    &.decent-carousel-outer {
      padding-bottom: @hhr-carousel-aspect-ratio;
    }
    .header-box,
    .carousel {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 0;
    }
  }
}

// Header images with caption
.header-img-size-box {
  position: relative;
  .header-intro {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
  }
}